import { useHistory } from 'react-router-dom'
import './BeforeTest.scss'

export function BeforeTest() {
  const history = useHistory()

  return (
    <div className='before-test-container'>
      <a href="/"><img src={require('assets/images/home/skeleton-1.png').default} alt='skeleton' width={250} /></a>
      <img className='mindfulness-img' src={require('assets/images/home/mindfulness.png').default} alt='warning' />
      <img className='text-img' src={require('assets/images/home/before-test-texts.png').default} alt='warning' width={600} />
      
      <button className='start-examn' onClick={() => history.push('/capture')}>I am ready to test</button>
    </div>
  )
}
