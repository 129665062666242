import { Button } from '@fluentui/react-northstar'
import { useHistory } from 'react-router-dom'
import './Home.scss'

export function Home() {
  const history = useHistory()

  return (
    <div className='home-container'>
      <br />
      <a href="/"><img src={require('assets/images/home/skeleton-1.png').default} alt='skeleton' width={250} /></a>
      <br />
      <h1 className='page-title' style={{fontWeight: 400}}>Welcome to hpe wellbeing</h1>
      <br />
      <button className='start-examn' onClick={() =>     history.push('/disclaimers')}>Start my exam</button>
      <br />
      <br />
      <br />
      <img className='stats-img' src={require('assets/images/home/person-with-stats.png').default} alt='stats' width={500} style={{opacity: 0.3}}/>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div>
        <ul className='footer-menu'>
          <li>
            <a href="/">Privacy Policy</a>
          </li>
          <li>
            <a href="/">Terms and Conditions</a>
          </li>
        </ul>
      </div>
    </div>
  )
}
