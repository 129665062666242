import { useHistory } from 'react-router-dom'
import './Disclaimers.scss'

export function Disclaimers() {
  const history = useHistory()

  return (
    <div className='disclaimers-container'>
      <br />
      <a href="/"><img src={require('assets/images/home/skeleton-1.png').default} alt='skeleton' width={250} /></a>
      <br />
      <br />
      <img src={require('assets/images/home/warning-icon.png').default} alt='warning' width={100} />
      <br />
      <br />
      <br />
      <div style={{lineHeight: 2}}>
        Our software solution is not a replacement for a healthcare professional. 
        Our software solution does not diagnose, prevent or provide treatment 
        for any form of illness or disease and should only be considered 
        a solution to provide health and wellness data only. 
        If you feel unwell or have any medical condition always 
        seek medical help regardless of your results.
      </div>
      <br />
      <br />
      <br />
      <button className='start-examn' onClick={() =>history.push('/before-test')}>Got it</button>
    </div>
  )
}
