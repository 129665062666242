
var Module = (function() {
  var _scriptDir = typeof document !== 'undefined' && document.currentScript ? document.currentScript.src : undefined;
  
  return (
function(Module) {
  Module = Module || {};


var b;b||(b=typeof Module !== 'undefined' ? Module : {});var aa,ba;b.ready=new Promise(function(a,c){aa=a;ba=c});Object.getOwnPropertyDescriptor(b.ready,"_main")||(Object.defineProperty(b.ready,"_main",{configurable:!0,get:function(){f("You are getting _main on the Promise object, instead of the instance. Use .then() to get called back with the instance, see the MODULARIZE docs in src/settings.js")}}),Object.defineProperty(b.ready,"_main",{configurable:!0,set:function(){f("You are setting _main on the Promise object, instead of the instance. Use .then() to get called back with the instance, see the MODULARIZE docs in src/settings.js")}}));
Object.getOwnPropertyDescriptor(b.ready,"_emscripten_stack_get_end")||(Object.defineProperty(b.ready,"_emscripten_stack_get_end",{configurable:!0,get:function(){f("You are getting _emscripten_stack_get_end on the Promise object, instead of the instance. Use .then() to get called back with the instance, see the MODULARIZE docs in src/settings.js")}}),Object.defineProperty(b.ready,"_emscripten_stack_get_end",{configurable:!0,set:function(){f("You are setting _emscripten_stack_get_end on the Promise object, instead of the instance. Use .then() to get called back with the instance, see the MODULARIZE docs in src/settings.js")}}));
Object.getOwnPropertyDescriptor(b.ready,"_emscripten_stack_get_free")||(Object.defineProperty(b.ready,"_emscripten_stack_get_free",{configurable:!0,get:function(){f("You are getting _emscripten_stack_get_free on the Promise object, instead of the instance. Use .then() to get called back with the instance, see the MODULARIZE docs in src/settings.js")}}),Object.defineProperty(b.ready,"_emscripten_stack_get_free",{configurable:!0,set:function(){f("You are setting _emscripten_stack_get_free on the Promise object, instead of the instance. Use .then() to get called back with the instance, see the MODULARIZE docs in src/settings.js")}}));
Object.getOwnPropertyDescriptor(b.ready,"_emscripten_stack_init")||(Object.defineProperty(b.ready,"_emscripten_stack_init",{configurable:!0,get:function(){f("You are getting _emscripten_stack_init on the Promise object, instead of the instance. Use .then() to get called back with the instance, see the MODULARIZE docs in src/settings.js")}}),Object.defineProperty(b.ready,"_emscripten_stack_init",{configurable:!0,set:function(){f("You are setting _emscripten_stack_init on the Promise object, instead of the instance. Use .then() to get called back with the instance, see the MODULARIZE docs in src/settings.js")}}));
Object.getOwnPropertyDescriptor(b.ready,"_stackSave")||(Object.defineProperty(b.ready,"_stackSave",{configurable:!0,get:function(){f("You are getting _stackSave on the Promise object, instead of the instance. Use .then() to get called back with the instance, see the MODULARIZE docs in src/settings.js")}}),Object.defineProperty(b.ready,"_stackSave",{configurable:!0,set:function(){f("You are setting _stackSave on the Promise object, instead of the instance. Use .then() to get called back with the instance, see the MODULARIZE docs in src/settings.js")}}));
Object.getOwnPropertyDescriptor(b.ready,"_stackRestore")||(Object.defineProperty(b.ready,"_stackRestore",{configurable:!0,get:function(){f("You are getting _stackRestore on the Promise object, instead of the instance. Use .then() to get called back with the instance, see the MODULARIZE docs in src/settings.js")}}),Object.defineProperty(b.ready,"_stackRestore",{configurable:!0,set:function(){f("You are setting _stackRestore on the Promise object, instead of the instance. Use .then() to get called back with the instance, see the MODULARIZE docs in src/settings.js")}}));
Object.getOwnPropertyDescriptor(b.ready,"_stackAlloc")||(Object.defineProperty(b.ready,"_stackAlloc",{configurable:!0,get:function(){f("You are getting _stackAlloc on the Promise object, instead of the instance. Use .then() to get called back with the instance, see the MODULARIZE docs in src/settings.js")}}),Object.defineProperty(b.ready,"_stackAlloc",{configurable:!0,set:function(){f("You are setting _stackAlloc on the Promise object, instead of the instance. Use .then() to get called back with the instance, see the MODULARIZE docs in src/settings.js")}}));
Object.getOwnPropertyDescriptor(b.ready,"___wasm_call_ctors")||(Object.defineProperty(b.ready,"___wasm_call_ctors",{configurable:!0,get:function(){f("You are getting ___wasm_call_ctors on the Promise object, instead of the instance. Use .then() to get called back with the instance, see the MODULARIZE docs in src/settings.js")}}),Object.defineProperty(b.ready,"___wasm_call_ctors",{configurable:!0,set:function(){f("You are setting ___wasm_call_ctors on the Promise object, instead of the instance. Use .then() to get called back with the instance, see the MODULARIZE docs in src/settings.js")}}));
Object.getOwnPropertyDescriptor(b.ready,"_fflush")||(Object.defineProperty(b.ready,"_fflush",{configurable:!0,get:function(){f("You are getting _fflush on the Promise object, instead of the instance. Use .then() to get called back with the instance, see the MODULARIZE docs in src/settings.js")}}),Object.defineProperty(b.ready,"_fflush",{configurable:!0,set:function(){f("You are setting _fflush on the Promise object, instead of the instance. Use .then() to get called back with the instance, see the MODULARIZE docs in src/settings.js")}}));
Object.getOwnPropertyDescriptor(b.ready,"___errno_location")||(Object.defineProperty(b.ready,"___errno_location",{configurable:!0,get:function(){f("You are getting ___errno_location on the Promise object, instead of the instance. Use .then() to get called back with the instance, see the MODULARIZE docs in src/settings.js")}}),Object.defineProperty(b.ready,"___errno_location",{configurable:!0,set:function(){f("You are setting ___errno_location on the Promise object, instead of the instance. Use .then() to get called back with the instance, see the MODULARIZE docs in src/settings.js")}}));
Object.getOwnPropertyDescriptor(b.ready,"_malloc")||(Object.defineProperty(b.ready,"_malloc",{configurable:!0,get:function(){f("You are getting _malloc on the Promise object, instead of the instance. Use .then() to get called back with the instance, see the MODULARIZE docs in src/settings.js")}}),Object.defineProperty(b.ready,"_malloc",{configurable:!0,set:function(){f("You are setting _malloc on the Promise object, instead of the instance. Use .then() to get called back with the instance, see the MODULARIZE docs in src/settings.js")}}));
Object.getOwnPropertyDescriptor(b.ready,"_free")||(Object.defineProperty(b.ready,"_free",{configurable:!0,get:function(){f("You are getting _free on the Promise object, instead of the instance. Use .then() to get called back with the instance, see the MODULARIZE docs in src/settings.js")}}),Object.defineProperty(b.ready,"_free",{configurable:!0,set:function(){f("You are setting _free on the Promise object, instead of the instance. Use .then() to get called back with the instance, see the MODULARIZE docs in src/settings.js")}}));
Object.getOwnPropertyDescriptor(b.ready,"_setThrew")||(Object.defineProperty(b.ready,"_setThrew",{configurable:!0,get:function(){f("You are getting _setThrew on the Promise object, instead of the instance. Use .then() to get called back with the instance, see the MODULARIZE docs in src/settings.js")}}),Object.defineProperty(b.ready,"_setThrew",{configurable:!0,set:function(){f("You are setting _setThrew on the Promise object, instead of the instance. Use .then() to get called back with the instance, see the MODULARIZE docs in src/settings.js")}}));
Object.getOwnPropertyDescriptor(b.ready,"_emscripten_main_thread_process_queued_calls")||(Object.defineProperty(b.ready,"_emscripten_main_thread_process_queued_calls",{configurable:!0,get:function(){f("You are getting _emscripten_main_thread_process_queued_calls on the Promise object, instead of the instance. Use .then() to get called back with the instance, see the MODULARIZE docs in src/settings.js")}}),Object.defineProperty(b.ready,"_emscripten_main_thread_process_queued_calls",{configurable:!0,
set:function(){f("You are setting _emscripten_main_thread_process_queued_calls on the Promise object, instead of the instance. Use .then() to get called back with the instance, see the MODULARIZE docs in src/settings.js")}}));
Object.getOwnPropertyDescriptor(b.ready,"onRuntimeInitialized")||(Object.defineProperty(b.ready,"onRuntimeInitialized",{configurable:!0,get:function(){f("You are getting onRuntimeInitialized on the Promise object, instead of the instance. Use .then() to get called back with the instance, see the MODULARIZE docs in src/settings.js")}}),Object.defineProperty(b.ready,"onRuntimeInitialized",{configurable:!0,set:function(){f("You are setting onRuntimeInitialized on the Promise object, instead of the instance. Use .then() to get called back with the instance, see the MODULARIZE docs in src/settings.js")}}));
b.ma||(b.ma=0);b.ma++;
(function(a){function c(p,n,q){var t=new XMLHttpRequest;t.open("GET",p,!0);t.responseType="arraybuffer";t.onprogress=function(v){var l=n;v.total&&(l=v.total);if(v.loaded){t.U?b.N[p].loaded=v.loaded:(t.U=!0,b.N||(b.N={}),b.N[p]={loaded:v.loaded,total:l});var u=l=v=0,x;for(x in b.N){var B=b.N[x];v+=B.total;l+=B.loaded;u++}v=Math.ceil(v*b.ma/u);b.setStatus&&b.setStatus("Downloading data... ("+l+"/"+v+")")}else!b.N&&b.setStatus&&b.setStatus("Downloading data...")};t.onerror=function(){throw Error("NetworkError for: "+p);
};t.onload=function(){if(200==t.status||304==t.status||206==t.status||0==t.status&&t.response)q(t.response);else throw Error(t.statusText+" : "+t.responseURL);};t.send(null)}function d(p){console.error("package error:",p)}function e(){function p(y,D,H){this.start=y;this.La=D;this.audio=H}function n(y,D,H,E,M,oa){for(var Va=y.transaction(["PACKAGES"],"readwrite").objectStore("PACKAGES"),ha=0,X=0,pa=Math.ceil(H.byteLength/67108864),N=0,qa=0;qa<pa;qa++){X+=67108864;var Ea=Va.put(H.slice(ha,X),"package/"+
D+"/"+qa);ha=X;Ea.onsuccess=function(){N++;if(N==pa){var ia=y.transaction(["METADATA"],"readwrite").objectStore("METADATA").put({uuid:E.yb,chunkCount:pa},"metadata/"+D);ia.onsuccess=function(){M(H)};ia.onerror=function(kd){oa(kd)}}};Ea.onerror=function(ia){oa(ia)}}}function q(y,D,H,E){y=y.transaction(["METADATA"],"readonly").objectStore("METADATA").get("metadata/"+D);y.onsuccess=function(M){return(M=M.target.result)?H(m===M.uuid,M):H(!1,null)};y.onerror=function(M){E(M)}}function t(y,D,H,E,M){y=y.transaction(["PACKAGES"],
"readonly").objectStore("PACKAGES");var oa=0,Va=0,ha=H.chunkCount,X=Array(ha);for(H=0;H<ha;H++){var pa=y.get("package/"+D+"/"+H);pa.onsuccess=function(N){if(1==ha)E(N.target.result);else if(oa++,Va+=N.target.result.byteLength,X.push(N.target.result),oa==ha)if(1==oa)E(N.target.result);else{N=new Uint8Array(Va);var qa=0,Ea;for(Ea in X){var ia=X[Ea];N.set(new Uint8Array(ia),qa);qa+=ia.byteLength}X=void 0;E(N.buffer)}};pa.onerror=function(N){M(N)}}}function v(y){if(!y)throw"Loading data file failed."+
Error().stack;if(!(y instanceof ArrayBuffer))throw"bad input to processPackageData"+Error().stack;y=new Uint8Array(y);p.prototype.Ra=y;y=a.files;for(var D=0;D<y.length;++D)p.prototype.U[y[D].filename].onload();b.removeRunDependency("datafile_./rppg-client/public/rppg.data")}function l(y){console.error(y);console.error("falling back to default preload behavior");c(h,k,v,d)}p.prototype={U:{},open:function(y,D){this.name=D;this.U[D]=this;b.addRunDependency("fp "+this.name)},onload:function(){var y=this.Ra.subarray(this.start,
this.La);this.Pa(y)},Pa:function(y){b.FS_createDataFile(this.name,null,y,!0,!0,!0);b.removeRunDependency("fp "+this.name);this.U[this.name]=null}};for(var u=a.files,x=0;x<u.length;++x)(new p(u[x].start,u[x].end,u[x].audio)).open("GET",u[x].filename);if("object"===typeof window)var B=window.indexedDB||window.mozIndexedDB||window.webkitIndexedDB||window.msIndexedDB;else if("undefined"!==typeof location)B=self.indexedDB;else throw"using IndexedDB to cache data can only be done on a web page or in a web worker";
b.addRunDependency("datafile_./rppg-client/public/rppg.data");b.Fa||(b.Fa={});(function(y,D){try{var H=B.open("EM_PRELOAD_CACHE",1)}catch(E){return D(E)}H.onupgradeneeded=function(E){E=E.target.result;E.objectStoreNames.contains("PACKAGES")&&E.deleteObjectStore("PACKAGES");E.createObjectStore("PACKAGES");E.objectStoreNames.contains("METADATA")&&E.deleteObjectStore("METADATA");E.createObjectStore("METADATA")};H.onsuccess=function(E){y(E.target.result)};H.onerror=function(E){D(E)}})(function(y){q(y,
g+"./rppg-client/public/rppg.data",function(D,H){b.Fa["./rppg-client/public/rppg.data"]={Wd:D};D?t(y,g+"./rppg-client/public/rppg.data",H,v,l):c(h,k,function(E){n(y,g+"./rppg-client/public/rppg.data",E,{yb:m},v,function(M){console.error(M);v(E)})},l)},l)},l);b.setStatus&&b.setStatus("Downloading...")}if("object"===typeof window)var g=window.encodeURIComponent(window.location.pathname.toString().substring(0,window.location.pathname.toString().lastIndexOf("/"))+"/");else if("undefined"!==typeof location)g=
encodeURIComponent(location.pathname.toString().substring(0,location.pathname.toString().lastIndexOf("/"))+"/");else throw"using preloaded data can only be done on a web page or in a web worker";"function"!==typeof b.locateFilePackage||b.locateFile||(b.locateFile=b.locateFilePackage,r("warning: you defined Module.locateFilePackage, that has been renamed to Module.locateFile (using your locateFilePackage for now)"));var h=b.locateFile?b.locateFile("rppg.data",""):"rppg.data",k=a.remote_package_size,
m=a.package_uuid;b.calledRun?e():(b.preRun||(b.preRun=[]),b.preRun.push(e))})({files:[{filename:"/haar_facedetection.xml",start:0,end:837462,audio:0},{filename:"/haarcascade_frontalface_alt.xml",start:837462,end:1757333,audio:0},{filename:"/landmark-model.bin",start:1757333,end:12345367,audio:0}],remote_package_size:12345367,package_uuid:"a7f0e92c-b26f-4495-bf61-14c82aed7460"});var ca=b.preRun.slice();
if(!b.preRun)throw"Module.preRun should exist because file support used it; did a pre-js delete it?";ca.forEach(function(a){if(0>b.preRun.indexOf(a))throw"All preRun tasks that exist before user pre-js code should remain after; did you replace Module or modify Module.preRun?";});var da={},w;for(w in b)b.hasOwnProperty(w)&&(da[w]=b[w]);var ea="./this.program";function fa(a,c){throw c;}
if(b.ENVIRONMENT)throw Error("Module.ENVIRONMENT has been deprecated. To force the environment, use the ENVIRONMENT compile-time option (for example, -s ENVIRONMENT=web or -s ENVIRONMENT=node)");var z="",ja;"undefined"!==typeof document&&document.currentScript&&(z=document.currentScript.src);_scriptDir&&(z=_scriptDir);0!==z.indexOf("blob:")?z=z.substr(0,z.lastIndexOf("/")+1):z="";
if("object"!==typeof window&&"function"!==typeof importScripts)throw Error("not compiled for this environment (did you build to HTML and try to run it not on the web, or set ENVIRONMENT to something - like node - and run it someplace else - like on the web?)");ja=function(a){var c=new XMLHttpRequest;c.open("GET",a,!1);c.send(null);return c.responseText};var ka=b.print||console.log.bind(console),r=b.printErr||console.warn.bind(console);for(w in da)da.hasOwnProperty(w)&&(b[w]=da[w]);da=null;
Object.getOwnPropertyDescriptor(b,"arguments")||Object.defineProperty(b,"arguments",{configurable:!0,get:function(){f("Module.arguments has been replaced with plain arguments_ (the initial value can be provided on Module, but after startup the value is only looked for on a local variable of that name)")}});b.thisProgram&&(ea=b.thisProgram);Object.getOwnPropertyDescriptor(b,"thisProgram")||Object.defineProperty(b,"thisProgram",{configurable:!0,get:function(){f("Module.thisProgram has been replaced with plain thisProgram (the initial value can be provided on Module, but after startup the value is only looked for on a local variable of that name)")}});
b.quit&&(fa=b.quit);Object.getOwnPropertyDescriptor(b,"quit")||Object.defineProperty(b,"quit",{configurable:!0,get:function(){f("Module.quit has been replaced with plain quit_ (the initial value can be provided on Module, but after startup the value is only looked for on a local variable of that name)")}});A("undefined"===typeof b.memoryInitializerPrefixURL,"Module.memoryInitializerPrefixURL option was removed, use Module.locateFile instead");A("undefined"===typeof b.pthreadMainPrefixURL,"Module.pthreadMainPrefixURL option was removed, use Module.locateFile instead");
A("undefined"===typeof b.cdInitializerPrefixURL,"Module.cdInitializerPrefixURL option was removed, use Module.locateFile instead");A("undefined"===typeof b.filePackagePrefixURL,"Module.filePackagePrefixURL option was removed, use Module.locateFile instead");A("undefined"===typeof b.read,"Module.read option was removed (modify read_ in JS)");A("undefined"===typeof b.readAsync,"Module.readAsync option was removed (modify readAsync in JS)");A("undefined"===typeof b.readBinary,"Module.readBinary option was removed (modify readBinary in JS)");
A("undefined"===typeof b.setWindowTitle,"Module.setWindowTitle option was removed (modify setWindowTitle in JS)");A("undefined"===typeof b.TOTAL_MEMORY,"Module.TOTAL_MEMORY has been renamed Module.INITIAL_MEMORY");Object.getOwnPropertyDescriptor(b,"read")||Object.defineProperty(b,"read",{configurable:!0,get:function(){f("Module.read has been replaced with plain read_ (the initial value can be provided on Module, but after startup the value is only looked for on a local variable of that name)")}});
Object.getOwnPropertyDescriptor(b,"readAsync")||Object.defineProperty(b,"readAsync",{configurable:!0,get:function(){f("Module.readAsync has been replaced with plain readAsync (the initial value can be provided on Module, but after startup the value is only looked for on a local variable of that name)")}});Object.getOwnPropertyDescriptor(b,"readBinary")||Object.defineProperty(b,"readBinary",{configurable:!0,get:function(){f("Module.readBinary has been replaced with plain readBinary (the initial value can be provided on Module, but after startup the value is only looked for on a local variable of that name)")}});
Object.getOwnPropertyDescriptor(b,"setWindowTitle")||Object.defineProperty(b,"setWindowTitle",{configurable:!0,get:function(){f("Module.setWindowTitle has been replaced with plain setWindowTitle (the initial value can be provided on Module, but after startup the value is only looked for on a local variable of that name)")}});function la(a){ma||(ma={});ma[a]||(ma[a]=1,r(a))}var ma,na;b.wasmBinary&&(na=b.wasmBinary);
Object.getOwnPropertyDescriptor(b,"wasmBinary")||Object.defineProperty(b,"wasmBinary",{configurable:!0,get:function(){f("Module.wasmBinary has been replaced with plain wasmBinary (the initial value can be provided on Module, but after startup the value is only looked for on a local variable of that name)")}});var noExitRuntime;b.noExitRuntime&&(noExitRuntime=b.noExitRuntime);Object.getOwnPropertyDescriptor(b,"noExitRuntime")||Object.defineProperty(b,"noExitRuntime",{configurable:!0,get:function(){f("Module.noExitRuntime has been replaced with plain noExitRuntime (the initial value can be provided on Module, but after startup the value is only looked for on a local variable of that name)")}});
"object"!==typeof WebAssembly&&f("no native wasm support detected");var ra,sa=!1;function A(a,c){a||f("Assertion failed: "+c)}function ta(a){var c=b["_"+a];A(c,"Cannot call unknown function "+a+", make sure it is exported");return c}
function ua(a,c,d,e){var g={string:function(n){var q=0;if(null!==n&&void 0!==n&&0!==n){var t=(n.length<<2)+1;q=va(t);wa(n,q,t)}return q},array:function(n){var q=va(n.length);xa(n,q);return q}},h=ta(a),k=[];a=0;A("array"!==c,'Return type should not be "array".');if(e)for(var m=0;m<e.length;m++){var p=g[d[m]];p?(0===a&&(a=ya()),k[m]=p(e[m])):k[m]=e[m]}d=h.apply(null,k);d=function(n){return"string"===c?C(n):"boolean"===c?!!n:n}(d);0!==a&&za(a);return d}
var Aa="undefined"!==typeof TextDecoder?new TextDecoder("utf8"):void 0;
function Ba(a,c,d){var e=c+d;for(d=c;a[d]&&!(d>=e);)++d;if(16<d-c&&a.subarray&&Aa)return Aa.decode(a.subarray(c,d));for(e="";c<d;){var g=a[c++];if(g&128){var h=a[c++]&63;if(192==(g&224))e+=String.fromCharCode((g&31)<<6|h);else{var k=a[c++]&63;224==(g&240)?g=(g&15)<<12|h<<6|k:(240!=(g&248)&&la("Invalid UTF-8 leading byte 0x"+g.toString(16)+" encountered when deserializing a UTF-8 string on the asm.js/wasm heap to a JS string!"),g=(g&7)<<18|h<<12|k<<6|a[c++]&63);65536>g?e+=String.fromCharCode(g):(g-=
65536,e+=String.fromCharCode(55296|g>>10,56320|g&1023))}}else e+=String.fromCharCode(g)}return e}function C(a,c){return a?Ba(F,a,c):""}
function Ca(a,c,d,e){if(!(0<e))return 0;var g=d;e=d+e-1;for(var h=0;h<a.length;++h){var k=a.charCodeAt(h);if(55296<=k&&57343>=k){var m=a.charCodeAt(++h);k=65536+((k&1023)<<10)|m&1023}if(127>=k){if(d>=e)break;c[d++]=k}else{if(2047>=k){if(d+1>=e)break;c[d++]=192|k>>6}else{if(65535>=k){if(d+2>=e)break;c[d++]=224|k>>12}else{if(d+3>=e)break;2097152<=k&&la("Invalid Unicode code point 0x"+k.toString(16)+" encountered when serializing a JS string to an UTF-8 string on the asm.js/wasm heap! (Valid unicode code points should be in range 0-0x1FFFFF).");
c[d++]=240|k>>18;c[d++]=128|k>>12&63}c[d++]=128|k>>6&63}c[d++]=128|k&63}}c[d]=0;return d-g}function wa(a,c,d){A("number"==typeof d,"stringToUTF8(str, outPtr, maxBytesToWrite) is missing the third parameter that specifies the length of the output buffer!");Ca(a,F,c,d)}function Da(a){for(var c=0,d=0;d<a.length;++d){var e=a.charCodeAt(d);55296<=e&&57343>=e&&(e=65536+((e&1023)<<10)|a.charCodeAt(++d)&1023);127>=e?++c:c=2047>=e?c+2:65535>=e?c+3:c+4}return c}
var Fa="undefined"!==typeof TextDecoder?new TextDecoder("utf-16le"):void 0;function Ga(a,c){A(0==a%2,"Pointer passed to UTF16ToString must be aligned to two bytes!");var d=a>>1;for(var e=d+c/2;!(d>=e)&&Ha[d];)++d;d<<=1;if(32<d-a&&Fa)return Fa.decode(F.subarray(a,d));d="";for(e=0;!(e>=c/2);++e){var g=Ia[a+2*e>>1];if(0==g)break;d+=String.fromCharCode(g)}return d}
function Ja(a,c,d){A(0==c%2,"Pointer passed to stringToUTF16 must be aligned to two bytes!");A("number"==typeof d,"stringToUTF16(str, outPtr, maxBytesToWrite) is missing the third parameter that specifies the length of the output buffer!");void 0===d&&(d=2147483647);if(2>d)return 0;d-=2;var e=c;d=d<2*a.length?d/2:a.length;for(var g=0;g<d;++g)Ia[c>>1]=a.charCodeAt(g),c+=2;Ia[c>>1]=0;return c-e}function Ka(a){return 2*a.length}
function La(a,c){A(0==a%4,"Pointer passed to UTF32ToString must be aligned to four bytes!");for(var d=0,e="";!(d>=c/4);){var g=G[a+4*d>>2];if(0==g)break;++d;65536<=g?(g-=65536,e+=String.fromCharCode(55296|g>>10,56320|g&1023)):e+=String.fromCharCode(g)}return e}
function Ma(a,c,d){A(0==c%4,"Pointer passed to stringToUTF32 must be aligned to four bytes!");A("number"==typeof d,"stringToUTF32(str, outPtr, maxBytesToWrite) is missing the third parameter that specifies the length of the output buffer!");void 0===d&&(d=2147483647);if(4>d)return 0;var e=c;d=e+d-4;for(var g=0;g<a.length;++g){var h=a.charCodeAt(g);if(55296<=h&&57343>=h){var k=a.charCodeAt(++g);h=65536+((h&1023)<<10)|k&1023}G[c>>2]=h;c+=4;if(c+4>d)break}G[c>>2]=0;return c-e}
function Na(a){for(var c=0,d=0;d<a.length;++d){var e=a.charCodeAt(d);55296<=e&&57343>=e&&++d;c+=4}return c}function xa(a,c){A(0<=a.length,"writeArrayToMemory array must have a length (should be an array or typed array)");I.set(a,c)}var Oa,I,F,Ia,Ha,G,J,Pa,Qa;
function Ra(){var a=ra.buffer;Oa=a;b.HEAP8=I=new Int8Array(a);b.HEAP16=Ia=new Int16Array(a);b.HEAP32=G=new Int32Array(a);b.HEAPU8=F=new Uint8Array(a);b.HEAPU16=Ha=new Uint16Array(a);b.HEAPU32=J=new Uint32Array(a);b.HEAPF32=Pa=new Float32Array(a);b.HEAPF64=Qa=new Float64Array(a)}b.TOTAL_STACK&&A(5242880===b.TOTAL_STACK,"the stack size can no longer be determined at runtime");var Sa=b.INITIAL_MEMORY||83886080;
Object.getOwnPropertyDescriptor(b,"INITIAL_MEMORY")||Object.defineProperty(b,"INITIAL_MEMORY",{configurable:!0,get:function(){f("Module.INITIAL_MEMORY has been replaced with plain INITIAL_MEMORY (the initial value can be provided on Module, but after startup the value is only looked for on a local variable of that name)")}});A(5242880<=Sa,"INITIAL_MEMORY should be larger than TOTAL_STACK, was "+Sa+"! (TOTAL_STACK=5242880)");
A("undefined"!==typeof Int32Array&&"undefined"!==typeof Float64Array&&void 0!==Int32Array.prototype.subarray&&void 0!==Int32Array.prototype.set,"JS engine does not provide full typed array support");A(!b.wasmMemory,"Use of `wasmMemory` detected.  Use -s IMPORTED_MEMORY to define wasmMemory externally");A(83886080==Sa,"Detected runtime INITIAL_MEMORY setting.  Use -s IMPORTED_MEMORY to define wasmMemory dynamically");var Ta;
function Ua(){var a=Wa();A(0==(a&3));J[(a>>2)+1]=34821223;J[(a>>2)+2]=2310721022;G[0]=1668509029}function Xa(){if(!sa){var a=Wa(),c=J[(a>>2)+1];a=J[(a>>2)+2];34821223==c&&2310721022==a||f("Stack overflow! Stack cookie has been overwritten, expected hex dwords 0x89BACDFE and 0x2135467, but received 0x"+a.toString(16)+" "+c.toString(16));1668509029!==G[0]&&f("Runtime error: The application has corrupted its heap memory area (address zero)!")}}var Ya=new Int16Array(1),Za=new Int8Array(Ya.buffer);
Ya[0]=25459;if(115!==Za[0]||99!==Za[1])throw"Runtime error: expected the system to be little-endian!";var $a=[],ab=[],bb=[],cb=[],db=!1,eb=!1;function fb(){var a=b.preRun.shift();$a.unshift(a)}A(Math.imul,"This browser does not support Math.imul(), build with LEGACY_VM_SUPPORT or POLYFILL_OLD_MATH_FUNCTIONS to add in a polyfill");A(Math.fround,"This browser does not support Math.fround(), build with LEGACY_VM_SUPPORT or POLYFILL_OLD_MATH_FUNCTIONS to add in a polyfill");A(Math.clz32,"This browser does not support Math.clz32(), build with LEGACY_VM_SUPPORT or POLYFILL_OLD_MATH_FUNCTIONS to add in a polyfill");
A(Math.trunc,"This browser does not support Math.trunc(), build with LEGACY_VM_SUPPORT or POLYFILL_OLD_MATH_FUNCTIONS to add in a polyfill");var gb=0,hb=null,ib=null,jb={};function kb(a){for(var c=a;;){if(!jb[a])return a;a=c+Math.random()}}
function lb(a){gb++;b.monitorRunDependencies&&b.monitorRunDependencies(gb);a?(A(!jb[a]),jb[a]=1,null===hb&&"undefined"!==typeof setInterval&&(hb=setInterval(function(){if(sa)clearInterval(hb),hb=null;else{var c=!1,d;for(d in jb)c||(c=!0,r("still waiting on run dependencies:")),r("dependency: "+d);c&&r("(end of list)")}},1E4))):r("warning: run dependency added without ID")}
function mb(a){gb--;b.monitorRunDependencies&&b.monitorRunDependencies(gb);a?(A(jb[a]),delete jb[a]):r("warning: run dependency removed without ID");0==gb&&(null!==hb&&(clearInterval(hb),hb=null),ib&&(a=ib,ib=null,a()))}b.preloadedImages={};b.preloadedAudios={};
function f(a){if(b.onAbort)b.onAbort(a);r(a);sa=!0;a="abort("+a+") at ";a:{var c=Error();if(!c.stack){try{throw Error();}catch(d){c=d}if(!c.stack){c="(no stack trace available)";break a}}c=c.stack.toString()}b.extraStackTrace&&(c+="\n"+b.extraStackTrace());c=nb(c);a=new WebAssembly.RuntimeError(a+c);ba(a);throw a;}function ob(){var a=pb;return String.prototype.startsWith?a.startsWith("data:application/octet-stream;base64,"):0===a.indexOf("data:application/octet-stream;base64,")}
function K(a){return function(){var c=b.asm;A(db,"native function `"+a+"` called before runtime initialization");A(!eb,"native function `"+a+"` called after runtime exit (use NO_EXIT_RUNTIME to keep it alive after main() exits)");c[a]||A(c[a],"exported native function `"+a+"` not found");return c[a].apply(null,arguments)}}var pb="rppg.wasm";if(!ob()){var qb=pb;pb=b.locateFile?b.locateFile(qb,z):z+qb}
function rb(){try{if(na)return new Uint8Array(na);throw"both async and sync fetching of the wasm failed";}catch(a){f(a)}}function sb(){return na||"function"!==typeof fetch?Promise.resolve().then(rb):fetch(pb,{credentials:"same-origin"}).then(function(a){if(!a.ok)throw"failed to load wasm binary file at '"+pb+"'";return a.arrayBuffer()}).catch(function(){return rb()})}var tb,ub;
function vb(a){for(;0<a.length;){var c=a.shift();if("function"==typeof c)c(b);else{var d=c.Qa;"number"===typeof d?void 0===c.la?Ta.get(d)():Ta.get(d)(c.la):d(void 0===c.la?null:c.la)}}}function nb(a){return a.replace(/\b_Z[\w\d_]+/g,function(c){la("warning: build with  -s DEMANGLE_SUPPORT=1  to link in libcxxabi demangling");return c===c?c:c+" ["+c+"]"})}
function wb(a){this.h=a-16;this.ob=function(c){G[this.h+8>>2]=c};this.lb=function(c){G[this.h+0>>2]=c};this.mb=function(){G[this.h+4>>2]=0};this.kb=function(){I[this.h+12>>0]=0};this.nb=function(){I[this.h+13>>0]=0};this.za=function(c,d){this.ob(c);this.lb(d);this.mb();this.kb();this.nb()}}var xb=0;function yb(a,c){for(var d=0,e=a.length-1;0<=e;e--){var g=a[e];"."===g?a.splice(e,1):".."===g?(a.splice(e,1),d++):d&&(a.splice(e,1),d--)}if(c)for(;d;d--)a.unshift("..");return a}
function zb(a){var c="/"===a.charAt(0),d="/"===a.substr(-1);(a=yb(a.split("/").filter(function(e){return!!e}),!c).join("/"))||c||(a=".");a&&d&&(a+="/");return(c?"/":"")+a}function Ab(a){var c=/^(\/?|)([\s\S]*?)((?:\.{1,2}|[^\/]+?|)(\.[^.\/]*|))(?:[\/]*)$/.exec(a).slice(1);a=c[0];c=c[1];if(!a&&!c)return".";c&&(c=c.substr(0,c.length-1));return a+c}function Bb(a){if("/"===a)return"/";a=zb(a);a=a.replace(/\/$/,"");var c=a.lastIndexOf("/");return-1===c?a:a.substr(c+1)}
function Cb(){if("object"===typeof crypto&&"function"===typeof crypto.getRandomValues){var a=new Uint8Array(1);return function(){crypto.getRandomValues(a);return a[0]}}return function(){f("no cryptographic support found for randomDevice. consider polyfilling it if you want to use something insecure like Math.random(), e.g. put this in a --pre-js: var crypto = { getRandomValues: function(array) { for (var i = 0; i < array.length; i++) array[i] = (Math.random()*256)|0 } };")}}
function Db(){for(var a="",c=!1,d=arguments.length-1;-1<=d&&!c;d--){c=0<=d?arguments[d]:"/";if("string"!==typeof c)throw new TypeError("Arguments to path.resolve must be strings");if(!c)return"";a=c+"/"+a;c="/"===c.charAt(0)}a=yb(a.split("/").filter(function(e){return!!e}),!c).join("/");return(c?"/":"")+a||"."}var Eb=[];function Fb(a,c){Eb[a]={input:[],o:[],S:c};Gb(a,Hb)}
var Hb={open:function(a){var c=Eb[a.node.Z];if(!c)throw new L(43);a.m=c;a.seekable=!1},close:function(a){a.m.S.flush(a.m)},flush:function(a){a.m.S.flush(a.m)},read:function(a,c,d,e){if(!a.m||!a.m.S.ya)throw new L(60);for(var g=0,h=0;h<e;h++){try{var k=a.m.S.ya(a.m)}catch(m){throw new L(29);}if(void 0===k&&0===g)throw new L(6);if(null===k||void 0===k)break;g++;c[d+h]=k}g&&(a.node.timestamp=Date.now());return g},write:function(a,c,d,e){if(!a.m||!a.m.S.pa)throw new L(60);try{for(var g=0;g<e;g++)a.m.S.pa(a.m,
c[d+g])}catch(h){throw new L(29);}e&&(a.node.timestamp=Date.now());return g}},Jb={ya:function(a){if(!a.input.length){var c=null;"undefined"!=typeof window&&"function"==typeof window.prompt?(c=window.prompt("Input: "),null!==c&&(c+="\n")):"function"==typeof readline&&(c=readline(),null!==c&&(c+="\n"));if(!c)return null;a.input=Ib(c,!0)}return a.input.shift()},pa:function(a,c){null===c||10===c?(ka(Ba(a.o,0)),a.o=[]):0!=c&&a.o.push(c)},flush:function(a){a.o&&0<a.o.length&&(ka(Ba(a.o,0)),a.o=[])}},Kb=
{pa:function(a,c){null===c||10===c?(r(Ba(a.o,0)),a.o=[]):0!=c&&a.o.push(c)},flush:function(a){a.o&&0<a.o.length&&(r(Ba(a.o,0)),a.o=[])}},O={H:null,I:function(){return O.createNode(null,"/",16895,0)},createNode:function(a,c,d,e){if(24576===(d&61440)||4096===(d&61440))throw new L(63);O.H||(O.H={dir:{node:{K:O.f.K,A:O.f.A,W:O.f.W,fa:O.f.fa,Ia:O.f.Ia,ka:O.f.ka,Ja:O.f.Ja,Ha:O.f.Ha,ga:O.f.ga},stream:{M:O.g.M}},file:{node:{K:O.f.K,A:O.f.A},stream:{M:O.g.M,read:O.g.read,write:O.g.write,ta:O.g.ta,Ca:O.g.Ca,
Ea:O.g.Ea}},link:{node:{K:O.f.K,A:O.f.A,$:O.f.$},stream:{}},ua:{node:{K:O.f.K,A:O.f.A},stream:Lb}});d=Mb(a,c,d,e);16384===(d.mode&61440)?(d.f=O.H.dir.node,d.g=O.H.dir.stream,d.c={}):32768===(d.mode&61440)?(d.f=O.H.file.node,d.g=O.H.file.stream,d.j=0,d.c=null):40960===(d.mode&61440)?(d.f=O.H.link.node,d.g=O.H.link.stream):8192===(d.mode&61440)&&(d.f=O.H.ua.node,d.g=O.H.ua.stream);d.timestamp=Date.now();a&&(a.c[c]=d);return d},Xd:function(a){if(a.c&&a.c.subarray){for(var c=[],d=0;d<a.j;++d)c.push(a.c[d]);
return c}return a.c},Yd:function(a){return a.c?a.c.subarray?a.c.subarray(0,a.j):new Uint8Array(a.c):new Uint8Array(0)},wa:function(a,c){var d=a.c?a.c.length:0;d>=c||(c=Math.max(c,d*(1048576>d?2:1.125)>>>0),0!=d&&(c=Math.max(c,256)),d=a.c,a.c=new Uint8Array(c),0<a.j&&a.c.set(d.subarray(0,a.j),0))},ib:function(a,c){if(a.j!=c)if(0==c)a.c=null,a.j=0;else{if(!a.c||a.c.subarray){var d=a.c;a.c=new Uint8Array(c);d&&a.c.set(d.subarray(0,Math.min(c,a.j)))}else if(a.c||(a.c=[]),a.c.length>c)a.c.length=c;else for(;a.c.length<
c;)a.c.push(0);a.j=c}},f:{K:function(a){var c={};c.Vd=8192===(a.mode&61440)?a.id:1;c.$d=a.id;c.mode=a.mode;c.ce=1;c.uid=0;c.Zd=0;c.Z=a.Z;16384===(a.mode&61440)?c.size=4096:32768===(a.mode&61440)?c.size=a.j:40960===(a.mode&61440)?c.size=a.link.length:c.size=0;c.Sd=new Date(a.timestamp);c.be=new Date(a.timestamp);c.Ud=new Date(a.timestamp);c.Ka=4096;c.Td=Math.ceil(c.size/c.Ka);return c},A:function(a,c){void 0!==c.mode&&(a.mode=c.mode);void 0!==c.timestamp&&(a.timestamp=c.timestamp);void 0!==c.size&&
O.ib(a,c.size)},W:function(){throw Nb[44];},fa:function(a,c,d,e){return O.createNode(a,c,d,e)},Ia:function(a,c,d){if(16384===(a.mode&61440)){try{var e=Ob(c,d)}catch(h){}if(e)for(var g in e.c)throw new L(55);}delete a.parent.c[a.name];a.name=d;c.c[d]=a;a.parent=c},ka:function(a,c){delete a.c[c]},Ja:function(a,c){var d=Ob(a,c),e;for(e in d.c)throw new L(55);delete a.c[c]},Ha:function(a){var c=[".",".."],d;for(d in a.c)a.c.hasOwnProperty(d)&&c.push(d);return c},ga:function(a,c,d){a=O.createNode(a,c,
41471,0);a.link=d;return a},$:function(a){if(40960!==(a.mode&61440))throw new L(28);return a.link}},g:{read:function(a,c,d,e,g){var h=a.node.c;if(g>=a.node.j)return 0;a=Math.min(a.node.j-g,e);A(0<=a);if(8<a&&h.subarray)c.set(h.subarray(g,g+a),d);else for(e=0;e<a;e++)c[d+e]=h[g+e];return a},write:function(a,c,d,e,g,h){A(!(c instanceof ArrayBuffer));c.buffer===I.buffer&&(h=!1);if(!e)return 0;a=a.node;a.timestamp=Date.now();if(c.subarray&&(!a.c||a.c.subarray)){if(h)return A(0===g,"canOwn must imply no weird position inside the file"),
a.c=c.subarray(d,d+e),a.j=e;if(0===a.j&&0===g)return a.c=c.slice(d,d+e),a.j=e;if(g+e<=a.j)return a.c.set(c.subarray(d,d+e),g),e}O.wa(a,g+e);if(a.c.subarray&&c.subarray)a.c.set(c.subarray(d,d+e),g);else for(h=0;h<e;h++)a.c[g+h]=c[d+h];a.j=Math.max(a.j,g+e);return e},M:function(a,c,d){1===d?c+=a.position:2===d&&32768===(a.node.mode&61440)&&(c+=a.node.j);if(0>c)throw new L(28);return c},ta:function(a,c,d){O.wa(a.node,c+d);a.node.j=Math.max(a.node.j,c+d)},Ca:function(a,c,d,e,g,h){A(0===c);if(32768!==
(a.node.mode&61440))throw new L(43);a=a.node.c;if(h&2||a.buffer!==Oa){if(0<e||e+d<a.length)a.subarray?a=a.subarray(e,e+d):a=Array.prototype.slice.call(a,e,e+d);e=!0;h=16384*Math.ceil(d/16384);for(c=Pb(h);d<h;)I[c+d++]=0;d=c;if(!d)throw new L(48);I.set(a,d)}else e=!1,d=a.byteOffset;return{h:d,Qd:e}},Ea:function(a,c,d,e,g){if(32768!==(a.node.mode&61440))throw new L(43);if(g&2)return 0;O.g.write(a,c,0,e,d,!1);return 0}}},Qb={0:"Success",1:"Arg list too long",2:"Permission denied",3:"Address already in use",
4:"Address not available",5:"Address family not supported by protocol family",6:"No more processes",7:"Socket already connected",8:"Bad file number",9:"Trying to read unreadable message",10:"Mount device busy",11:"Operation canceled",12:"No children",13:"Connection aborted",14:"Connection refused",15:"Connection reset by peer",16:"File locking deadlock error",17:"Destination address required",18:"Math arg out of domain of func",19:"Quota exceeded",20:"File exists",21:"Bad address",22:"File too large",
23:"Host is unreachable",24:"Identifier removed",25:"Illegal byte sequence",26:"Connection already in progress",27:"Interrupted system call",28:"Invalid argument",29:"I/O error",30:"Socket is already connected",31:"Is a directory",32:"Too many symbolic links",33:"Too many open files",34:"Too many links",35:"Message too long",36:"Multihop attempted",37:"File or path name too long",38:"Network interface is not configured",39:"Connection reset by network",40:"Network is unreachable",41:"Too many open files in system",
42:"No buffer space available",43:"No such device",44:"No such file or directory",45:"Exec format error",46:"No record locks available",47:"The link has been severed",48:"Not enough core",49:"No message of desired type",50:"Protocol not available",51:"No space left on device",52:"Function not implemented",53:"Socket is not connected",54:"Not a directory",55:"Directory not empty",56:"State not recoverable",57:"Socket operation on non-socket",59:"Not a typewriter",60:"No such device or address",61:"Value too large for defined data type",
62:"Previous owner died",63:"Not super-user",64:"Broken pipe",65:"Protocol error",66:"Unknown protocol",67:"Protocol wrong type for socket",68:"Math result not representable",69:"Read only file system",70:"Illegal seek",71:"No such process",72:"Stale file handle",73:"Connection timed out",74:"Text file busy",75:"Cross-device link",100:"Device not a stream",101:"Bad font file fmt",102:"Invalid slot",103:"Invalid request code",104:"No anode",105:"Block device required",106:"Channel number out of range",
107:"Level 3 halted",108:"Level 3 reset",109:"Link number out of range",110:"Protocol driver not attached",111:"No CSI structure available",112:"Level 2 halted",113:"Invalid exchange",114:"Invalid request descriptor",115:"Exchange full",116:"No data (for no delay io)",117:"Timer expired",118:"Out of streams resources",119:"Machine is not on the network",120:"Package not installed",121:"The object is remote",122:"Advertise error",123:"Srmount error",124:"Communication error on send",125:"Cross mount point (not really error)",
126:"Given log. name not unique",127:"f.d. invalid for this operation",128:"Remote address changed",129:"Can   access a needed shared lib",130:"Accessing a corrupted shared lib",131:".lib section in a.out corrupted",132:"Attempting to link in too many libs",133:"Attempting to exec a shared library",135:"Streams pipe error",136:"Too many users",137:"Socket type not supported",138:"Not supported",139:"Protocol family not supported",140:"Can't send after socket shutdown",141:"Too many references",142:"Host is down",
148:"No medium (in tape drive)",156:"Level 2 not synchronized"},Rb={qd:63,Pc:44,Dd:71,lc:27,nc:29,md:60,zb:1,Qc:45,Ib:8,Rb:12,Fb:6,Nd:6,Uc:48,Ab:2,dc:21,cd:105,Pb:10,cc:20,Od:75,Oc:43,ed:54,pc:31,mc:28,Jc:41,Bc:33,kd:59,Kd:74,ec:22,Zc:51,Cd:70,zd:69,Cc:34,sd:64,$b:18,wd:68,Vc:49,ic:24,Sb:106,rc:156,sc:107,tc:108,zc:109,Ld:110,Mc:111,qc:112,Xb:16,Rc:46,Hb:113,Lb:114,Pd:115,Kc:104,Mb:103,Nb:102,Yb:16,Ob:101,ad:100,Nc:116,Hd:117,$c:118,Wc:119,Xc:120,yd:121,Sc:47,Db:122,Ed:123,Tb:124,td:65,Ec:36,ac:125,
Kb:9,ld:126,Jb:127,xd:128,uc:129,vc:130,yc:131,xc:132,wc:133,bd:52,fd:55,Fc:37,Ac:32,nd:138,rd:139,Wb:15,Lc:42,Eb:5,vd:67,hd:57,Yc:50,Ad:140,Vb:14,Bb:3,Ub:13,Ic:40,Gc:38,Id:73,fc:142,hc:23,kc:26,Gb:7,Zb:17,Dc:35,ud:66,Bd:137,Cb:4,Hc:39,oc:30,dd:53,Jd:141,Md:136,bc:19,Fd:72,jd:138,Tc:148,jc:25,od:61,Qb:11,gd:56,pd:62,Gd:135},Sb=null,Tb={},Ub=[],Vb=1,Wb=null,Xb=!0,Yb={},L=null,Nb={};
function P(a,c){a=Db("/",a);c=c||{};if(!a)return{path:"",node:null};var d={xa:!0,ra:0},e;for(e in d)void 0===c[e]&&(c[e]=d[e]);if(8<c.ra)throw new L(32);a=yb(a.split("/").filter(function(k){return!!k}),!1);var g=Sb;d="/";for(e=0;e<a.length;e++){var h=e===a.length-1;if(h&&c.parent)break;g=Ob(g,a[e]);d=zb(d+"/"+a[e]);g.X&&(!h||h&&c.xa)&&(g=g.X.root);if(!h||c.V)for(h=0;40960===(g.mode&61440);)if(g=Zb(d),d=Db(Ab(d),g),g=P(d,{ra:c.ra}).node,40<h++)throw new L(32);}return{path:d,node:g}}
function $b(a){for(var c;;){if(a===a.parent)return a=a.I.Da,c?"/"!==a[a.length-1]?a+"/"+c:a+c:a;c=c?a.name+"/"+c:a.name;a=a.parent}}function ac(a,c){for(var d=0,e=0;e<c.length;e++)d=(d<<5)-d+c.charCodeAt(e)|0;return(a+d>>>0)%Wb.length}function Ob(a,c){var d;if(d=(d=bc(a,"x"))?d:a.f.W?0:2)throw new L(d,a);for(d=Wb[ac(a.id,c)];d;d=d.R){var e=d.name;if(d.parent.id===a.id&&e===c)return d}return a.f.W(a,c)}function Mb(a,c,d,e){a=new cc(a,c,d,e);c=ac(a.parent.id,a.name);a.R=Wb[c];return Wb[c]=a}
var dc={r:0,"r+":2,w:577,"w+":578,a:1089,"a+":1090};function ec(a){var c=["r","w","rw"][a&3];a&512&&(c+="w");return c}function bc(a,c){if(Xb)return 0;if(-1===c.indexOf("r")||a.mode&292){if(-1!==c.indexOf("w")&&!(a.mode&146)||-1!==c.indexOf("x")&&!(a.mode&73))return 2}else return 2;return 0}function fc(a,c){try{return Ob(a,c),20}catch(d){}return bc(a,"wx")}function gc(a){var c=4096;for(a=a||0;a<=c;a++)if(!Ub[a])return a;throw new L(33);}
function hc(a,c){ic||(ic=function(){},ic.prototype={object:{get:function(){return this.node},set:function(g){this.node=g}}});var d=new ic,e;for(e in a)d[e]=a[e];a=d;c=gc(c);a.s=c;return Ub[c]=a}var Lb={open:function(a){a.g=Tb[a.node.Z].g;a.g.open&&a.g.open(a)},M:function(){throw new L(70);}};function Gb(a,c){Tb[a]={g:c}}
function jc(a,c){if("string"===typeof a)throw a;var d="/"===c,e=!c;if(d&&Sb)throw new L(10);if(!d&&!e){var g=P(c,{xa:!1});c=g.path;g=g.node;if(g.X)throw new L(10);if(16384!==(g.mode&61440))throw new L(54);}c={type:a,ee:{},Da:c,ab:[]};a=a.I(c);a.I=c;c.root=a;d?Sb=a:g&&(g.X=c,g.I&&g.I.ab.push(c))}function kc(a,c,d){var e=P(a,{parent:!0}).node;a=Bb(a);if(!a||"."===a||".."===a)throw new L(28);var g=fc(e,a);if(g)throw new L(g);if(!e.f.fa)throw new L(63);return e.f.fa(e,a,c,d)}
function Q(a){kc(a,16895,0)}function lc(a,c,d){"undefined"===typeof d&&(d=c,c=438);return kc(a,c|8192,d)}function mc(a,c){if(!Db(a))throw new L(44);var d=P(c,{parent:!0}).node;if(!d)throw new L(44);c=Bb(c);var e=fc(d,c);if(e)throw new L(e);if(!d.f.ga)throw new L(63);d.f.ga(d,c,a)}
function nc(a){var c=P(a,{parent:!0}).node,d=Bb(a),e=Ob(c,d);a:{try{var g=Ob(c,d)}catch(k){g=k.C;break a}var h=bc(c,"wx");g=h?h:16384===(g.mode&61440)?31:0}if(g)throw new L(g);if(!c.f.ka)throw new L(63);if(e.X)throw new L(10);try{Yb.willDeletePath&&Yb.willDeletePath(a)}catch(k){r("FS.trackingDelegate['willDeletePath']('"+a+"') threw an exception: "+k.message)}c.f.ka(c,d);c=ac(e.parent.id,e.name);if(Wb[c]===e)Wb[c]=e.R;else for(c=Wb[c];c;){if(c.R===e){c.R=e.R;break}c=c.R}try{if(Yb.onDeletePath)Yb.onDeletePath(a)}catch(k){r("FS.trackingDelegate['onDeletePath']('"+
a+"') threw an exception: "+k.message)}}function Zb(a){a=P(a).node;if(!a)throw new L(44);if(!a.f.$)throw new L(28);return Db($b(a.parent),a.f.$(a))}function oc(a,c){var d;"string"===typeof a?d=P(a,{V:!0}).node:d=a;if(!d.f.A)throw new L(63);d.f.A(d,{mode:c&4095|d.mode&-4096,timestamp:Date.now()})}
function pc(a,c,d,e){if(""===a)throw new L(44);if("string"===typeof c){var g=dc[c];if("undefined"===typeof g)throw Error("Unknown file open mode: "+c);c=g}d=c&64?("undefined"===typeof d?438:d)&4095|32768:0;if("object"===typeof a)var h=a;else{a=zb(a);try{h=P(a,{V:!(c&131072)}).node}catch(m){}}g=!1;if(c&64)if(h){if(c&128)throw new L(20);}else h=kc(a,d,0),g=!0;if(!h)throw new L(44);8192===(h.mode&61440)&&(c&=-513);if(c&65536&&16384!==(h.mode&61440))throw new L(54);if(!g&&(d=h?40960===(h.mode&61440)?
32:16384===(h.mode&61440)&&("r"!==ec(c)||c&512)?31:bc(h,ec(c)):44))throw new L(d);if(c&512){d=h;var k;"string"===typeof d?k=P(d,{V:!0}).node:k=d;if(!k.f.A)throw new L(63);if(16384===(k.mode&61440))throw new L(31);if(32768!==(k.mode&61440))throw new L(28);if(d=bc(k,"w"))throw new L(d);k.f.A(k,{size:0,timestamp:Date.now()})}c&=-131713;e=hc({node:h,path:$b(h),flags:c,seekable:!0,position:0,g:h.g,xb:[],error:!1},e);e.g.open&&e.g.open(e);!b.logReadFiles||c&1||(qc||(qc={}),a in qc||(qc[a]=1,r("FS.trackingDelegate error on read file: "+
a)));try{Yb.onOpenFile&&(h=0,1!==(c&2097155)&&(h|=1),0!==(c&2097155)&&(h|=2),Yb.onOpenFile(a,h))}catch(m){r("FS.trackingDelegate['onOpenFile']('"+a+"', flags) threw an exception: "+m.message)}return e}function rc(a){if(null===a.s)throw new L(8);a.na&&(a.na=null);try{a.g.close&&a.g.close(a)}catch(c){throw c;}finally{Ub[a.s]=null}a.s=null}function sc(a,c,d){if(null===a.s)throw new L(8);if(!a.seekable||!a.g.M)throw new L(70);if(0!=d&&1!=d&&2!=d)throw new L(28);a.position=a.g.M(a,c,d);a.xb=[]}
function tc(a,c,d,e,g,h){if(0>e||0>g)throw new L(28);if(null===a.s)throw new L(8);if(0===(a.flags&2097155))throw new L(8);if(16384===(a.node.mode&61440))throw new L(31);if(!a.g.write)throw new L(28);a.seekable&&a.flags&1024&&sc(a,0,2);var k="undefined"!==typeof g;if(!k)g=a.position;else if(!a.seekable)throw new L(70);c=a.g.write(a,c,d,e,g,h);k||(a.position+=c);try{if(a.path&&Yb.onWriteToFile)Yb.onWriteToFile(a.path)}catch(m){r("FS.trackingDelegate['onWriteToFile']('"+a.path+"') threw an exception: "+
m.message)}return c}function uc(){L||(L=function(a,c){this.node=c;this.jb=function(d){this.C=d;for(var e in Rb)if(Rb[e]===d){this.code=e;break}};this.jb(a);this.message=Qb[a];this.stack&&(Object.defineProperty(this,"stack",{value:Error().stack,writable:!0}),this.stack=nb(this.stack))},L.prototype=Error(),L.prototype.constructor=L,[44].forEach(function(a){Nb[a]=new L(a);Nb[a].stack="<generic error, no stack>"}))}var vc;function wc(a,c){var d=0;a&&(d|=365);c&&(d|=146);return d}
function xc(a,c){a="string"===typeof a?a:$b(a);for(c=c.split("/").reverse();c.length;){var d=c.pop();if(d){var e=zb(a+"/"+d);try{Q(e)}catch(g){}a=e}}return e}function yc(a,c,d,e){a=zb(("string"===typeof a?a:$b(a))+"/"+c);d=wc(d,e);return kc(a,(void 0!==d?d:438)&4095|32768,0)}
function zc(a,c,d,e,g,h){a=c?zb(("string"===typeof a?a:$b(a))+"/"+c):a;e=wc(e,g);g=kc(a,(void 0!==e?e:438)&4095|32768,0);if(d){if("string"===typeof d){a=Array(d.length);c=0;for(var k=d.length;c<k;++c)a[c]=d.charCodeAt(c);d=a}oc(g,e|146);a=pc(g,577);tc(a,d,0,d.length,0,h);rc(a);oc(g,e)}return g}
function R(a,c,d,e){a=zb(("string"===typeof a?a:$b(a))+"/"+c);c=wc(!!d,!!e);R.Ba||(R.Ba=64);var g=R.Ba++<<8|0;Gb(g,{open:function(h){h.seekable=!1},close:function(){e&&e.buffer&&e.buffer.length&&e(10)},read:function(h,k,m,p){for(var n=0,q=0;q<p;q++){try{var t=d()}catch(v){throw new L(29);}if(void 0===t&&0===n)throw new L(6);if(null===t||void 0===t)break;n++;k[m+q]=t}n&&(h.node.timestamp=Date.now());return n},write:function(h,k,m,p){for(var n=0;n<p;n++)try{e(k[m+n])}catch(q){throw new L(29);}p&&(h.node.timestamp=
Date.now());return n}});return lc(a,c,g)}function Ac(a){if(!(a.Aa||a.Za||a.link||a.c)){if("undefined"!==typeof XMLHttpRequest)throw Error("Lazy loading should have been performed (contents set) in createLazyFile, but it was not. Lazy loading only works in web workers. Use --embed-file or --preload-file in emcc on the main thread.");if(ja)try{a.c=Ib(ja(a.url),!0),a.j=a.c.length}catch(c){throw new L(29);}else throw Error("Cannot load without read() or XMLHttpRequest.");}}
function Bc(a,c,d,e,g){if("undefined"!==typeof XMLHttpRequest)throw"Cannot do synchronous binary XHRs outside webworkers in modern browsers. Use --embed-file or --preload-file in emcc";d={Aa:!1,url:d};var h=yc(a,c,e,g);d.c?h.c=d.c:d.url&&(h.c=null,h.url=d.url);Object.defineProperties(h,{j:{get:function(){return this.c.length}}});var k={};Object.keys(h.g).forEach(function(m){var p=h.g[m];k[m]=function(){Ac(h);return p.apply(null,arguments)}});k.read=function(m,p,n,q,t){Ac(h);m=m.node.c;if(t>=m.length)return 0;
q=Math.min(m.length-t,q);A(0<=q);if(m.slice)for(var v=0;v<q;v++)p[n+v]=m[t+v];else for(v=0;v<q;v++)p[n+v]=m.get(t+v);return q};h.g=k;return h}function Cc(a,c,d,e,g,h,k,m,p,n){function q(l){function u(B){n&&n();m||zc(a,c,B,e,g,p);h&&h();mb(v)}var x=!1;b.preloadPlugins.forEach(function(B){!x&&B.canHandle(t)&&(B.handle(l,t,u,function(){k&&k();mb(v)}),x=!0)});x||u(l)}Dc.za();var t=c?Db(zb(a+"/"+c)):a,v=kb("cp "+t);lb(v);"string"==typeof d?Dc.Rd(d,function(l){q(l)},k):q(d)}var S={},ic,qc,Ec=void 0;
function Fc(){A(void 0!=Ec);Ec+=4;return G[Ec-4>>2]}function Gc(a){a=Ub[a];if(!a)throw new L(8);return a}var Hc={};function Ic(a){for(;a.length;){var c=a.pop();a.pop()(c)}}function Jc(a){return this.fromWireType(J[a>>2])}var Kc={},Lc={},Mc={};function Nc(a){if(void 0===a)return"_unknown";a=a.replace(/[^a-zA-Z0-9_]/g,"$");var c=a.charCodeAt(0);return 48<=c&&57>=c?"_"+a:a}
function Oc(a,c){a=Nc(a);return(new Function("body","return function "+a+'() {\n    "use strict";    return body.apply(this, arguments);\n};\n'))(c)}function Pc(a){var c=Error,d=Oc(a,function(e){this.name=a;this.message=e;e=Error(e).stack;void 0!==e&&(this.stack=this.toString()+"\n"+e.replace(/^Error(:[^\n]*)?\n/,""))});d.prototype=Object.create(c.prototype);d.prototype.constructor=d;d.prototype.toString=function(){return void 0===this.message?this.name:this.name+": "+this.message};return d}
var Qc=void 0;function Rc(a){throw new Qc(a);}function Sc(a,c,d){function e(m){m=d(m);m.length!==a.length&&Rc("Mismatched type converter count");for(var p=0;p<a.length;++p)T(a[p],m[p])}a.forEach(function(m){Mc[m]=c});var g=Array(c.length),h=[],k=0;c.forEach(function(m,p){Lc.hasOwnProperty(m)?g[p]=Lc[m]:(h.push(m),Kc.hasOwnProperty(m)||(Kc[m]=[]),Kc[m].push(function(){g[p]=Lc[m];++k;k===h.length&&e(g)}))});0===h.length&&e(g)}
function Tc(a){switch(a){case 1:return 0;case 2:return 1;case 4:return 2;case 8:return 3;default:throw new TypeError("Unknown type size: "+a);}}var Uc=void 0;function U(a){for(var c="";F[a];)c+=Uc[F[a++]];return c}var Vc=void 0;function V(a){throw new Vc(a);}
function T(a,c,d){d=d||{};if(!("argPackAdvance"in c))throw new TypeError("registerType registeredInstance requires argPackAdvance");var e=c.name;a||V('type "'+e+'" must have a positive integer typeid pointer');if(Lc.hasOwnProperty(a)){if(d.Xa)return;V("Cannot register type '"+e+"' twice")}Lc[a]=c;delete Mc[a];Kc.hasOwnProperty(a)&&(c=Kc[a],delete Kc[a],c.forEach(function(g){g()}))}function Wc(a){return{count:a.count,O:a.O,Y:a.Y,h:a.h,l:a.l,B:a.B,D:a.D}}
function Xc(a){V(a.b.l.i.name+" instance already deleted")}var Yc=!1;function Zc(){}function $c(a){--a.count.value;0===a.count.value&&(a.B?a.D.J(a.B):a.l.i.J(a.h))}function ad(a){if("undefined"===typeof FinalizationGroup)return ad=function(c){return c},a;Yc=new FinalizationGroup(function(c){for(var d=c.next();!d.done;d=c.next())d=d.value,d.h?$c(d):console.warn("object already deleted: "+d.h)});ad=function(c){Yc.register(c,c.b,c.b);return c};Zc=function(c){Yc.unregister(c.b)};return ad(a)}
var bd=void 0,cd=[];function dd(){for(;cd.length;){var a=cd.pop();a.b.O=!1;a["delete"]()}}function ed(){}var fd={};function gd(a,c,d){if(void 0===a[c].u){var e=a[c];a[c]=function(){a[c].u.hasOwnProperty(arguments.length)||V("Function '"+d+"' called with an invalid number of arguments ("+arguments.length+") - expects one of ("+a[c].u+")!");return a[c].u[arguments.length].apply(this,arguments)};a[c].u=[];a[c].u[e.ca]=e}}
function hd(a,c,d){b.hasOwnProperty(a)?((void 0===d||void 0!==b[a].u&&void 0!==b[a].u[d])&&V("Cannot register public name '"+a+"' twice"),gd(b,a,a),b.hasOwnProperty(d)&&V("Cannot register multiple overloads of a function with the same number of arguments ("+d+")!"),b[a].u[d]=c):(b[a]=c,void 0!==d&&(b[a].de=d))}function id(a,c,d,e,g,h,k,m){this.name=a;this.constructor=c;this.P=d;this.J=e;this.F=g;this.Sa=h;this.ba=k;this.Na=m;this.gb=[]}
function jd(a,c,d){for(;c!==d;)c.ba||V("Expected null or instance of "+d.name+", got an instance of "+c.name),a=c.ba(a),c=c.F;return a}function ld(a,c){if(null===c)return this.oa&&V("null is not a valid "+this.name),0;c.b||V('Cannot pass "'+md(c)+'" as a '+this.name);c.b.h||V("Cannot pass deleted object as a pointer of type "+this.name);return jd(c.b.h,c.b.l.i,this.i)}
function nd(a,c){if(null===c){this.oa&&V("null is not a valid "+this.name);if(this.ea){var d=this.qa();null!==a&&a.push(this.J,d);return d}return 0}c.b||V('Cannot pass "'+md(c)+'" as a '+this.name);c.b.h||V("Cannot pass deleted object as a pointer of type "+this.name);!this.da&&c.b.l.da&&V("Cannot convert argument of type "+(c.b.D?c.b.D.name:c.b.l.name)+" to parameter type "+this.name);d=jd(c.b.h,c.b.l.i,this.i);if(this.ea)switch(void 0===c.b.B&&V("Passing raw pointer to smart pointer is illegal"),
this.sb){case 0:c.b.D===this?d=c.b.B:V("Cannot convert argument of type "+(c.b.D?c.b.D.name:c.b.l.name)+" to parameter type "+this.name);break;case 1:d=c.b.B;break;case 2:if(c.b.D===this)d=c.b.B;else{var e=c.clone();d=this.hb(d,od(function(){e["delete"]()}));null!==a&&a.push(this.J,d)}break;default:V("Unsupporting sharing policy")}return d}
function pd(a,c){if(null===c)return this.oa&&V("null is not a valid "+this.name),0;c.b||V('Cannot pass "'+md(c)+'" as a '+this.name);c.b.h||V("Cannot pass deleted object as a pointer of type "+this.name);c.b.l.da&&V("Cannot convert argument of type "+c.b.l.name+" to parameter type "+this.name);return jd(c.b.h,c.b.l.i,this.i)}function qd(a,c,d){if(c===d)return a;if(void 0===d.F)return null;a=qd(a,c,d.F);return null===a?null:d.Na(a)}var rd={};
function sd(a,c){for(void 0===c&&V("ptr should not be undefined");a.F;)c=a.ba(c),a=a.F;return rd[c]}function td(a,c){c.l&&c.h||Rc("makeClassHandle requires ptr and ptrType");!!c.D!==!!c.B&&Rc("Both smartPtrType and smartPtr must be specified");c.count={value:1};return ad(Object.create(a,{b:{value:c}}))}function W(a,c,d,e){this.name=a;this.i=c;this.oa=d;this.da=e;this.ea=!1;this.J=this.hb=this.qa=this.Ga=this.sb=this.fb=void 0;void 0!==c.F?this.toWireType=nd:(this.toWireType=e?ld:pd,this.G=null)}
function ud(a,c,d){b.hasOwnProperty(a)||Rc("Replacing nonexistant public symbol");void 0!==b[a].u&&void 0!==d?b[a].u[d]=c:(b[a]=c,b[a].ca=d)}
function vd(a,c){A(0<=a.indexOf("j"),"getDynCaller should only be called with i64 sigs");var d=[];return function(){d.length=arguments.length;for(var e=0;e<arguments.length;e++)d[e]=arguments[e];-1!=a.indexOf("j")?(A("dynCall_"+a in b,"bad function pointer type - no table for sig '"+a+"'"),d&&d.length?A(d.length===a.substring(1).replace(/j/g,"--").length):A(1==a.length),e=d&&d.length?b["dynCall_"+a].apply(null,[c].concat(d)):b["dynCall_"+a].call(null,c)):(A(Ta.get(c),"missing table entry in dynCall: "+
c),e=Ta.get(c).apply(null,d));return e}}function Y(a,c){a=U(a);var d=-1!=a.indexOf("j")?vd(a,c):Ta.get(c);"function"!==typeof d&&V("unknown function pointer with signature "+a+": "+c);return d}var wd=void 0;function xd(a){a=yd(a);var c=U(a);zd(a);return c}function Ad(a,c){function d(h){g[h]||Lc[h]||(Mc[h]?Mc[h].forEach(d):(e.push(h),g[h]=!0))}var e=[],g={};c.forEach(d);throw new wd(a+": "+e.map(xd).join([", "]));}function Bd(a,c){for(var d=[],e=0;e<a;e++)d.push(G[(c>>2)+e]);return d}
function Cd(a){var c=Function;if(!(c instanceof Function))throw new TypeError("new_ called with constructor type "+typeof c+" which is not a function");var d=Oc(c.name||"unknownFunctionName",function(){});d.prototype=c.prototype;d=new d;a=c.apply(d,a);return a instanceof Object?a:d}
function Dd(a,c,d,e,g){var h=c.length;2>h&&V("argTypes array size mismatch! Must at least get return value and 'this' types!");var k=null!==c[1]&&null!==d,m=!1;for(d=1;d<c.length;++d)if(null!==c[d]&&void 0===c[d].G){m=!0;break}var p="void"!==c[0].name,n="",q="";for(d=0;d<h-2;++d)n+=(0!==d?", ":"")+"arg"+d,q+=(0!==d?", ":"")+"arg"+d+"Wired";a="return function "+Nc(a)+"("+n+") {\nif (arguments.length !== "+(h-2)+") {\nthrowBindingError('function "+a+" called with ' + arguments.length + ' arguments, expected "+
(h-2)+" args!');\n}\n";m&&(a+="var destructors = [];\n");var t=m?"destructors":"null";n="throwBindingError invoker fn runDestructors retType classParam".split(" ");e=[V,e,g,Ic,c[0],c[1]];k&&(a+="var thisWired = classParam.toWireType("+t+", this);\n");for(d=0;d<h-2;++d)a+="var arg"+d+"Wired = argType"+d+".toWireType("+t+", arg"+d+"); // "+c[d+2].name+"\n",n.push("argType"+d),e.push(c[d+2]);k&&(q="thisWired"+(0<q.length?", ":"")+q);a+=(p?"var rv = ":"")+"invoker(fn"+(0<q.length?", ":"")+q+");\n";if(m)a+=
"runDestructors(destructors);\n";else for(d=k?1:2;d<c.length;++d)h=1===d?"thisWired":"arg"+(d-2)+"Wired",null!==c[d].G&&(a+=h+"_dtor("+h+"); // "+c[d].name+"\n",n.push(h+"_dtor"),e.push(c[d].G));p&&(a+="var ret = retType.fromWireType(rv);\nreturn ret;\n");n.push(a+"}\n");return Cd(n).apply(null,e)}var Ed=[],Z=[{},{value:void 0},{value:null},{value:!0},{value:!1}];function Fd(a){4<a&&0===--Z[a].sa&&(Z[a]=void 0,Ed.push(a))}
function od(a){switch(a){case void 0:return 1;case null:return 2;case !0:return 3;case !1:return 4;default:var c=Ed.length?Ed.pop():Z.length;Z[c]={sa:1,value:a};return c}}function md(a){if(null===a)return"null";var c=typeof a;return"object"===c||"array"===c||"function"===c?a.toString():""+a}function Gd(a,c){switch(c){case 2:return function(d){return this.fromWireType(Pa[d>>2])};case 3:return function(d){return this.fromWireType(Qa[d>>3])};default:throw new TypeError("Unknown float type: "+a);}}
function Hd(a,c,d){switch(c){case 0:return d?function(e){return I[e]}:function(e){return F[e]};case 1:return d?function(e){return Ia[e>>1]}:function(e){return Ha[e>>1]};case 2:return d?function(e){return G[e>>2]}:function(e){return J[e>>2]};default:throw new TypeError("Unknown integer type: "+a);}}var Id;Id=function(){return performance.now()};var Jd={};
function Kd(){if(!Ld){var a={USER:"web_user",LOGNAME:"web_user",PATH:"/",PWD:"/",HOME:"/home/web_user",LANG:("object"===typeof navigator&&navigator.languages&&navigator.languages[0]||"C").replace("-","_")+".UTF-8",_:ea||"./this.program"},c;for(c in Jd)a[c]=Jd[c];var d=[];for(c in a)d.push(c+"="+a[c]);Ld=d}return Ld}var Ld;function Md(a){return 0===a%4&&(0!==a%100||0===a%400)}function Nd(a,c){for(var d=0,e=0;e<=c;d+=a[e++]);return d}
var Od=[31,29,31,30,31,30,31,31,30,31,30,31],Pd=[31,28,31,30,31,30,31,31,30,31,30,31];function Qd(a,c){for(a=new Date(a.getTime());0<c;){var d=a.getMonth(),e=(Md(a.getFullYear())?Od:Pd)[d];if(c>e-a.getDate())c-=e-a.getDate()+1,a.setDate(1),11>d?a.setMonth(d+1):(a.setMonth(0),a.setFullYear(a.getFullYear()+1));else{a.setDate(a.getDate()+c);break}}return a}
function Rd(a,c,d,e){function g(l,u,x){for(l="number"===typeof l?l.toString():l||"";l.length<u;)l=x[0]+l;return l}function h(l,u){return g(l,u,"0")}function k(l,u){function x(y){return 0>y?-1:0<y?1:0}var B;0===(B=x(l.getFullYear()-u.getFullYear()))&&0===(B=x(l.getMonth()-u.getMonth()))&&(B=x(l.getDate()-u.getDate()));return B}function m(l){switch(l.getDay()){case 0:return new Date(l.getFullYear()-1,11,29);case 1:return l;case 2:return new Date(l.getFullYear(),0,3);case 3:return new Date(l.getFullYear(),
0,2);case 4:return new Date(l.getFullYear(),0,1);case 5:return new Date(l.getFullYear()-1,11,31);case 6:return new Date(l.getFullYear()-1,11,30)}}function p(l){l=Qd(new Date(l.v+1900,0,1),l.ja);var u=new Date(l.getFullYear()+1,0,4),x=m(new Date(l.getFullYear(),0,4));u=m(u);return 0>=k(x,l)?0>=k(u,l)?l.getFullYear()+1:l.getFullYear():l.getFullYear()-1}var n=G[e+40>>2];e={vb:G[e>>2],ub:G[e+4>>2],ha:G[e+8>>2],aa:G[e+12>>2],T:G[e+16>>2],v:G[e+20>>2],ia:G[e+24>>2],ja:G[e+28>>2],fe:G[e+32>>2],tb:G[e+36>>
2],wb:n?C(n):""};d=C(d);n={"%c":"%a %b %d %H:%M:%S %Y","%D":"%m/%d/%y","%F":"%Y-%m-%d","%h":"%b","%r":"%I:%M:%S %p","%R":"%H:%M","%T":"%H:%M:%S","%x":"%m/%d/%y","%X":"%H:%M:%S","%Ec":"%c","%EC":"%C","%Ex":"%m/%d/%y","%EX":"%H:%M:%S","%Ey":"%y","%EY":"%Y","%Od":"%d","%Oe":"%e","%OH":"%H","%OI":"%I","%Om":"%m","%OM":"%M","%OS":"%S","%Ou":"%u","%OU":"%U","%OV":"%V","%Ow":"%w","%OW":"%W","%Oy":"%y"};for(var q in n)d=d.replace(new RegExp(q,"g"),n[q]);var t="Sunday Monday Tuesday Wednesday Thursday Friday Saturday".split(" "),
v="January February March April May June July August September October November December".split(" ");n={"%a":function(l){return t[l.ia].substring(0,3)},"%A":function(l){return t[l.ia]},"%b":function(l){return v[l.T].substring(0,3)},"%B":function(l){return v[l.T]},"%C":function(l){return h((l.v+1900)/100|0,2)},"%d":function(l){return h(l.aa,2)},"%e":function(l){return g(l.aa,2," ")},"%g":function(l){return p(l).toString().substring(2)},"%G":function(l){return p(l)},"%H":function(l){return h(l.ha,2)},
"%I":function(l){l=l.ha;0==l?l=12:12<l&&(l-=12);return h(l,2)},"%j":function(l){return h(l.aa+Nd(Md(l.v+1900)?Od:Pd,l.T-1),3)},"%m":function(l){return h(l.T+1,2)},"%M":function(l){return h(l.ub,2)},"%n":function(){return"\n"},"%p":function(l){return 0<=l.ha&&12>l.ha?"AM":"PM"},"%S":function(l){return h(l.vb,2)},"%t":function(){return"\t"},"%u":function(l){return l.ia||7},"%U":function(l){var u=new Date(l.v+1900,0,1),x=0===u.getDay()?u:Qd(u,7-u.getDay());l=new Date(l.v+1900,l.T,l.aa);return 0>k(x,
l)?h(Math.ceil((31-x.getDate()+(Nd(Md(l.getFullYear())?Od:Pd,l.getMonth()-1)-31)+l.getDate())/7),2):0===k(x,u)?"01":"00"},"%V":function(l){var u=new Date(l.v+1901,0,4),x=m(new Date(l.v+1900,0,4));u=m(u);var B=Qd(new Date(l.v+1900,0,1),l.ja);return 0>k(B,x)?"53":0>=k(u,B)?"01":h(Math.ceil((x.getFullYear()<l.v+1900?l.ja+32-x.getDate():l.ja+1-x.getDate())/7),2)},"%w":function(l){return l.ia},"%W":function(l){var u=new Date(l.v,0,1),x=1===u.getDay()?u:Qd(u,0===u.getDay()?1:7-u.getDay()+1);l=new Date(l.v+
1900,l.T,l.aa);return 0>k(x,l)?h(Math.ceil((31-x.getDate()+(Nd(Md(l.getFullYear())?Od:Pd,l.getMonth()-1)-31)+l.getDate())/7),2):0===k(x,u)?"01":"00"},"%y":function(l){return(l.v+1900).toString().substring(2)},"%Y":function(l){return l.v+1900},"%z":function(l){l=l.tb;var u=0<=l;l=Math.abs(l)/60;return(u?"+":"-")+String("0000"+(l/60*100+l%60)).slice(-4)},"%Z":function(l){return l.wb},"%%":function(){return"%"}};for(q in n)0<=d.indexOf(q)&&(d=d.replace(new RegExp(q,"g"),n[q](e)));q=Ib(d,!1);if(q.length>
c)return 0;xa(q,a);return q.length-1}function cc(a,c,d,e){a||(a=this);this.parent=a;this.I=a.I;this.X=null;this.id=Vb++;this.name=c;this.mode=d;this.f={};this.g={};this.Z=e}
Object.defineProperties(cc.prototype,{read:{get:function(){return 365===(this.mode&365)},set:function(a){a?this.mode|=365:this.mode&=-366}},write:{get:function(){return 146===(this.mode&146)},set:function(a){a?this.mode|=146:this.mode&=-147}},Za:{get:function(){return 16384===(this.mode&61440)}},Aa:{get:function(){return 8192===(this.mode&61440)}}});uc();Wb=Array(4096);jc(O,"/");Q("/tmp");Q("/home");Q("/home/web_user");
(function(){Q("/dev");Gb(259,{read:function(){return 0},write:function(c,d,e,g){return g}});lc("/dev/null",259);Fb(1280,Jb);Fb(1536,Kb);lc("/dev/tty",1280);lc("/dev/tty1",1536);var a=Cb();R("/dev","random",a);R("/dev","urandom",a);Q("/dev/shm");Q("/dev/shm/tmp")})();Q("/proc");Q("/proc/self");Q("/proc/self/fd");
jc({I:function(){var a=Mb("/proc/self","fd",16895,73);a.f={W:function(c,d){var e=Ub[+d];if(!e)throw new L(8);c={parent:null,I:{Da:"fake"},f:{$:function(){return e.path}}};return c.parent=c}};return a}},"/proc/self/fd");var Dc;b.FS_createPath=xc;b.FS_createDataFile=zc;b.FS_createPreloadedFile=Cc;b.FS_createLazyFile=Bc;b.FS_createDevice=R;b.FS_unlink=nc;Qc=b.InternalError=Pc("InternalError");for(var Sd=Array(256),Td=0;256>Td;++Td)Sd[Td]=String.fromCharCode(Td);Uc=Sd;Vc=b.BindingError=Pc("BindingError");
ed.prototype.isAliasOf=function(a){if(!(this instanceof ed&&a instanceof ed))return!1;var c=this.b.l.i,d=this.b.h,e=a.b.l.i;for(a=a.b.h;c.F;)d=c.ba(d),c=c.F;for(;e.F;)a=e.ba(a),e=e.F;return c===e&&d===a};ed.prototype.clone=function(){this.b.h||Xc(this);if(this.b.Y)return this.b.count.value+=1,this;var a=ad(Object.create(Object.getPrototypeOf(this),{b:{value:Wc(this.b)}}));a.b.count.value+=1;a.b.O=!1;return a};
ed.prototype["delete"]=function(){this.b.h||Xc(this);this.b.O&&!this.b.Y&&V("Object already scheduled for deletion");Zc(this);$c(this.b);this.b.Y||(this.b.B=void 0,this.b.h=void 0)};ed.prototype.isDeleted=function(){return!this.b.h};ed.prototype.deleteLater=function(){this.b.h||Xc(this);this.b.O&&!this.b.Y&&V("Object already scheduled for deletion");cd.push(this);1===cd.length&&bd&&bd(dd);this.b.O=!0;return this};W.prototype.Ta=function(a){this.Ga&&(a=this.Ga(a));return a};
W.prototype.va=function(a){this.J&&this.J(a)};W.prototype.argPackAdvance=8;W.prototype.readValueFromPointer=Jc;W.prototype.deleteObject=function(a){if(null!==a)a["delete"]()};
W.prototype.fromWireType=function(a){function c(){return this.ea?td(this.i.P,{l:this.fb,h:d,D:this,B:a}):td(this.i.P,{l:this,h:a})}var d=this.Ta(a);if(!d)return this.va(a),null;var e=sd(this.i,d);if(void 0!==e){if(0===e.b.count.value)return e.b.h=d,e.b.B=a,e.clone();e=e.clone();this.va(a);return e}e=this.i.Sa(d);e=fd[e];if(!e)return c.call(this);e=this.da?e.Ma:e.pointerType;var g=qd(d,this.i,e.i);return null===g?c.call(this):this.ea?td(e.i.P,{l:e,h:g,D:this,B:a}):td(e.i.P,{l:e,h:g})};
b.getInheritedInstanceCount=function(){return Object.keys(rd).length};b.getLiveInheritedInstances=function(){var a=[],c;for(c in rd)rd.hasOwnProperty(c)&&a.push(rd[c]);return a};b.flushPendingDeletes=dd;b.setDelayFunction=function(a){bd=a;cd.length&&bd&&bd(dd)};wd=b.UnboundTypeError=Pc("UnboundTypeError");b.count_emval_handles=function(){for(var a=0,c=5;c<Z.length;++c)void 0!==Z[c]&&++a;return a};b.get_first_emval=function(){for(var a=5;a<Z.length;++a)if(void 0!==Z[a])return Z[a];return null};
function Ib(a,c){var d=Array(Da(a)+1);a=Ca(a,d,0,d.length);c&&(d.length=a);return d}ab.push({Qa:function(){Ud()}});
var Wd={__assert_fail:function(a,c,d,e){f("Assertion failed: "+C(a)+", at: "+[c?C(c):"unknown filename",d,e?C(e):"unknown function"])},__cxa_allocate_exception:function(a){return Pb(a+16)+16},__cxa_atexit:function(){},__cxa_throw:function(a,c,d){(new wb(a)).za(c,d);xb++;throw a+" - Exception catching is disabled, this exception cannot be caught. Compile with -s DISABLE_EXCEPTION_CATCHING=0 or DISABLE_EXCEPTION_CATCHING=2 to catch.";},__sys_fcntl64:function(a,c,d){Ec=d;try{var e=Gc(a);switch(c){case 0:var g=
Fc();return 0>g?-28:pc(e.path,e.flags,0,g).s;case 1:case 2:return 0;case 3:return e.flags;case 4:return g=Fc(),e.flags|=g,0;case 12:return g=Fc(),Ia[g+0>>1]=2,0;case 13:case 14:return 0;case 16:case 8:return-28;case 9:return G[Vd()>>2]=28,-1;default:return-28}}catch(h){return"undefined"!==typeof S&&h instanceof L||f(h),-h.C}},__sys_ioctl:function(a,c,d){Ec=d;try{var e=Gc(a);switch(c){case 21509:case 21505:return e.m?0:-59;case 21510:case 21511:case 21512:case 21506:case 21507:case 21508:return e.m?
0:-59;case 21519:if(!e.m)return-59;var g=Fc();return G[g>>2]=0;case 21520:return e.m?-28:-59;case 21531:a=g=Fc();if(!e.g.Ya)throw new L(59);return e.g.Ya(e,c,a);case 21523:return e.m?0:-59;case 21524:return e.m?0:-59;default:f("bad ioctl syscall "+c)}}catch(h){return"undefined"!==typeof S&&h instanceof L||f(h),-h.C}},__sys_open:function(a,c,d){Ec=d;try{var e=C(a),g=Fc();return pc(e,c,g).s}catch(h){return"undefined"!==typeof S&&h instanceof L||f(h),-h.C}},_embind_finalize_value_array:function(a){var c=
Hc[a];delete Hc[a];var d=c.elements,e=d.length,g=d.map(function(m){return m.Wa}).concat(d.map(function(m){return m.qb})),h=c.qa,k=c.J;Sc([a],g,function(m){d.forEach(function(p,n){var q=m[n],t=p.Ua,v=p.Va,l=m[n+e],u=p.pb,x=p.rb;p.read=function(B){return q.fromWireType(t(v,B))};p.write=function(B,y){var D=[];u(x,B,l.toWireType(D,y));Ic(D)}});return[{name:c.name,fromWireType:function(p){for(var n=Array(e),q=0;q<e;++q)n[q]=d[q].read(p);k(p);return n},toWireType:function(p,n){if(e!==n.length)throw new TypeError("Incorrect number of tuple elements for "+
c.name+": expected="+e+", actual="+n.length);for(var q=h(),t=0;t<e;++t)d[t].write(q,n[t]);null!==p&&p.push(k,q);return q},argPackAdvance:8,readValueFromPointer:Jc,G:k}]})},_embind_register_bool:function(a,c,d,e,g){var h=Tc(d);c=U(c);T(a,{name:c,fromWireType:function(k){return!!k},toWireType:function(k,m){return m?e:g},argPackAdvance:8,readValueFromPointer:function(k){if(1===d)var m=I;else if(2===d)m=Ia;else if(4===d)m=G;else throw new TypeError("Unknown boolean type size: "+c);return this.fromWireType(m[k>>
h])},G:null})},_embind_register_class:function(a,c,d,e,g,h,k,m,p,n,q,t,v){q=U(q);h=Y(g,h);m&&(m=Y(k,m));n&&(n=Y(p,n));v=Y(t,v);var l=Nc(q);hd(l,function(){Ad("Cannot construct "+q+" due to unbound types",[e])});Sc([a,c,d],e?[e]:[],function(u){u=u[0];if(e){var x=u.i;var B=x.P}else B=ed.prototype;u=Oc(l,function(){if(Object.getPrototypeOf(this)!==y)throw new Vc("Use 'new' to construct "+q);if(void 0===D.L)throw new Vc(q+" has no accessible constructor");var E=D.L[arguments.length];if(void 0===E)throw new Vc("Tried to invoke ctor of "+
q+" with invalid number of parameters ("+arguments.length+") - expected ("+Object.keys(D.L).toString()+") parameters instead!");return E.apply(this,arguments)});var y=Object.create(B,{constructor:{value:u}});u.prototype=y;var D=new id(q,u,y,v,x,h,m,n);x=new W(q,D,!0,!1);B=new W(q+"*",D,!1,!1);var H=new W(q+" const*",D,!1,!0);fd[a]={pointerType:B,Ma:H};ud(l,u);return[x,B,H]})},_embind_register_class_constructor:function(a,c,d,e,g,h){A(0<c);var k=Bd(c,d);g=Y(e,g);var m=[h],p=[];Sc([],[a],function(n){n=
n[0];var q="constructor "+n.name;void 0===n.i.L&&(n.i.L=[]);if(void 0!==n.i.L[c-1])throw new Vc("Cannot register multiple constructors with identical number of parameters ("+(c-1)+") for class '"+n.name+"'! Overload resolution is currently only performed using the parameter count, not actual type info!");n.i.L[c-1]=function(){Ad("Cannot construct "+n.name+" due to unbound types",k)};Sc([],k,function(t){n.i.L[c-1]=function(){arguments.length!==c-1&&V(q+" called with "+arguments.length+" arguments, expected "+
(c-1));p.length=0;m.length=c;for(var v=1;v<c;++v)m[v]=t[v].toWireType(p,arguments[v-1]);v=g.apply(null,m);Ic(p);return t[0].fromWireType(v)};return[]});return[]})},_embind_register_class_function:function(a,c,d,e,g,h,k,m){var p=Bd(d,e);c=U(c);h=Y(g,h);Sc([],[a],function(n){function q(){Ad("Cannot call "+t+" due to unbound types",p)}n=n[0];var t=n.name+"."+c;m&&n.i.gb.push(c);var v=n.i.P,l=v[c];void 0===l||void 0===l.u&&l.className!==n.name&&l.ca===d-2?(q.ca=d-2,q.className=n.name,v[c]=q):(gd(v,c,
t),v[c].u[d-2]=q);Sc([],p,function(u){u=Dd(t,u,n,h,k);void 0===v[c].u?(u.ca=d-2,v[c]=u):v[c].u[d-2]=u;return[]});return[]})},_embind_register_emval:function(a,c){c=U(c);T(a,{name:c,fromWireType:function(d){var e=Z[d].value;Fd(d);return e},toWireType:function(d,e){return od(e)},argPackAdvance:8,readValueFromPointer:Jc,G:null})},_embind_register_float:function(a,c,d){d=Tc(d);c=U(c);T(a,{name:c,fromWireType:function(e){return e},toWireType:function(e,g){if("number"!==typeof g&&"boolean"!==typeof g)throw new TypeError('Cannot convert "'+
md(g)+'" to '+this.name);return g},argPackAdvance:8,readValueFromPointer:Gd(c,d),G:null})},_embind_register_function:function(a,c,d,e,g,h){var k=Bd(c,d);a=U(a);g=Y(e,g);hd(a,function(){Ad("Cannot call "+a+" due to unbound types",k)},c-1);Sc([],k,function(m){ud(a,Dd(a,[m[0],null].concat(m.slice(1)),null,g,h),c-1);return[]})},_embind_register_integer:function(a,c,d,e,g){function h(n){return n}c=U(c);-1===g&&(g=4294967295);var k=Tc(d);if(0===e){var m=32-8*d;h=function(n){return n<<m>>>m}}var p=-1!=c.indexOf("unsigned");
T(a,{name:c,fromWireType:h,toWireType:function(n,q){if("number"!==typeof q&&"boolean"!==typeof q)throw new TypeError('Cannot convert "'+md(q)+'" to '+this.name);if(q<e||q>g)throw new TypeError('Passing a number "'+md(q)+'" from JS side to C/C++ side to an argument of type "'+c+'", which is outside the valid range ['+e+", "+g+"]!");return p?q>>>0:q|0},argPackAdvance:8,readValueFromPointer:Hd(c,k,0!==e),G:null})},_embind_register_memory_view:function(a,c,d){function e(h){h>>=2;var k=J;return new g(Oa,
k[h+1],k[h])}var g=[Int8Array,Uint8Array,Int16Array,Uint16Array,Int32Array,Uint32Array,Float32Array,Float64Array][c];d=U(d);T(a,{name:d,fromWireType:e,argPackAdvance:8,readValueFromPointer:e},{Xa:!0})},_embind_register_std_string:function(a,c){c=U(c);var d="std::string"===c;T(a,{name:c,fromWireType:function(e){var g=J[e>>2];if(d)for(var h=e+4,k=0;k<=g;++k){var m=e+4+k;if(k==g||0==F[m]){h=C(h,m-h);if(void 0===p)var p=h;else p+=String.fromCharCode(0),p+=h;h=m+1}}else{p=Array(g);for(k=0;k<g;++k)p[k]=
String.fromCharCode(F[e+4+k]);p=p.join("")}zd(e);return p},toWireType:function(e,g){g instanceof ArrayBuffer&&(g=new Uint8Array(g));var h="string"===typeof g;h||g instanceof Uint8Array||g instanceof Uint8ClampedArray||g instanceof Int8Array||V("Cannot pass non-string to std::string");var k=(d&&h?function(){return Da(g)}:function(){return g.length})(),m=Pb(4+k+1);J[m>>2]=k;if(d&&h)wa(g,m+4,k+1);else if(h)for(h=0;h<k;++h){var p=g.charCodeAt(h);255<p&&(zd(m),V("String has UTF-16 code units that do not fit in 8 bits"));
F[m+4+h]=p}else for(h=0;h<k;++h)F[m+4+h]=g[h];null!==e&&e.push(zd,m);return m},argPackAdvance:8,readValueFromPointer:Jc,G:function(e){zd(e)}})},_embind_register_std_wstring:function(a,c,d){d=U(d);if(2===c){var e=Ga;var g=Ja;var h=Ka;var k=function(){return Ha};var m=1}else 4===c&&(e=La,g=Ma,h=Na,k=function(){return J},m=2);T(a,{name:d,fromWireType:function(p){for(var n=J[p>>2],q=k(),t,v=p+4,l=0;l<=n;++l){var u=p+4+l*c;if(l==n||0==q[u>>m])v=e(v,u-v),void 0===t?t=v:(t+=String.fromCharCode(0),t+=v),
v=u+c}zd(p);return t},toWireType:function(p,n){"string"!==typeof n&&V("Cannot pass non-string to C++ string type "+d);var q=h(n),t=Pb(4+q+c);J[t>>2]=q>>m;g(n,t+4,q+c);null!==p&&p.push(zd,t);return t},argPackAdvance:8,readValueFromPointer:Jc,G:function(p){zd(p)}})},_embind_register_value_array:function(a,c,d,e,g,h){Hc[a]={name:U(c),qa:Y(d,e),J:Y(g,h),elements:[]}},_embind_register_value_array_element:function(a,c,d,e,g,h,k,m,p){Hc[a].elements.push({Wa:c,Ua:Y(d,e),Va:g,qb:h,pb:Y(k,m),rb:p})},_embind_register_void:function(a,
c){c=U(c);T(a,{ae:!0,name:c,argPackAdvance:0,fromWireType:function(){},toWireType:function(){}})},_emval_decref:Fd,_emval_incref:function(a){4<a&&(Z[a].sa+=1)},_emval_take_value:function(a,c){var d=Lc[a];void 0===d&&V("_emval_take_value has unknown type "+xd(a));a=d.readValueFromPointer(c);return od(a)},abort:function(){f()},clock_gettime:function(a,c){if(0===a)a=Date.now();else if(1===a||4===a)a=Id();else return G[Vd()>>2]=28,-1;G[c>>2]=a/1E3|0;G[c+4>>2]=a%1E3*1E6|0;return 0},emscripten_memcpy_big:function(a,
c,d){F.copyWithin(a,c,c+d)},emscripten_resize_heap:function(a){a>>>=0;var c=F.length;A(a>c);if(2147483648<a)return r("Cannot enlarge memory, asked to go up to "+a+" bytes, but the limit is 2147483648 bytes!"),!1;for(var d=1;4>=d;d*=2){var e=c*(1+.2/d);e=Math.min(e,a+100663296);e=Math.max(16777216,a,e);0<e%65536&&(e+=65536-e%65536);e=Math.min(2147483648,e);a:{var g=e;try{ra.grow(g-Oa.byteLength+65535>>>16);Ra();var h=1;break a}catch(k){console.error("emscripten_realloc_buffer: Attempted to grow heap from "+
Oa.byteLength+" bytes to "+g+" bytes, but got error: "+k)}h=void 0}if(h)return!0}r("Failed to grow the heap from "+c+" bytes to "+e+" bytes, not enough memory!");return!1},environ_get:function(a,c){try{var d=0;Kd().forEach(function(e,g){var h=c+d;g=G[a+4*g>>2]=h;for(h=0;h<e.length;++h)A(e.charCodeAt(h)===e.charCodeAt(h)&255),I[g++>>0]=e.charCodeAt(h);I[g>>0]=0;d+=e.length+1});return 0}catch(e){return"undefined"!==typeof S&&e instanceof L||f(e),e.C}},environ_sizes_get:function(a,c){try{var d=Kd();
G[a>>2]=d.length;var e=0;d.forEach(function(g){e+=g.length+1});G[c>>2]=e;return 0}catch(g){return"undefined"!==typeof S&&g instanceof L||f(g),g.C}},fd_close:function(a){try{var c=Gc(a);rc(c);return 0}catch(d){return"undefined"!==typeof S&&d instanceof L||f(d),d.C}},fd_read:function(a,c,d,e){try{a:{for(var g=Gc(a),h=a=0;h<d;h++){var k=G[c+(8*h+4)>>2],m=g,p=G[c+8*h>>2],n=k,q=void 0,t=I;if(0>n||0>q)throw new L(28);if(null===m.s)throw new L(8);if(1===(m.flags&2097155))throw new L(8);if(16384===(m.node.mode&
61440))throw new L(31);if(!m.g.read)throw new L(28);var v="undefined"!==typeof q;if(!v)q=m.position;else if(!m.seekable)throw new L(70);var l=m.g.read(m,t,p,n,q);v||(m.position+=l);var u=l;if(0>u){var x=-1;break a}a+=u;if(u<k)break}x=a}G[e>>2]=x;return 0}catch(B){return"undefined"!==typeof S&&B instanceof L||f(B),B.C}},fd_seek:function(a,c,d,e,g){try{var h=Gc(a);a=4294967296*d+(c>>>0);if(-9007199254740992>=a||9007199254740992<=a)return-61;sc(h,a,e);ub=[h.position>>>0,(tb=h.position,1<=+Math.abs(tb)?
0<tb?(Math.min(+Math.floor(tb/4294967296),4294967295)|0)>>>0:~~+Math.ceil((tb-+(~~tb>>>0))/4294967296)>>>0:0)];G[g>>2]=ub[0];G[g+4>>2]=ub[1];h.na&&0===a&&0===e&&(h.na=null);return 0}catch(k){return"undefined"!==typeof S&&k instanceof L||f(k),k.C}},fd_write:function(a,c,d,e){try{a:{for(var g=Gc(a),h=a=0;h<d;h++){var k=tc(g,I,G[c+8*h>>2],G[c+(8*h+4)>>2],void 0);if(0>k){var m=-1;break a}a+=k}m=a}G[e>>2]=m;return 0}catch(p){return"undefined"!==typeof S&&p instanceof L||f(p),p.C}},setTempRet0:function(){},
strftime_l:function(a,c,d,e){return Rd(a,c,d,e)},sysconf:function(a){switch(a){case 30:return 16384;case 85:return 131072;case 132:case 133:case 12:case 137:case 138:case 15:case 235:case 16:case 17:case 18:case 19:case 20:case 149:case 13:case 10:case 236:case 153:case 9:case 21:case 22:case 159:case 154:case 14:case 77:case 78:case 139:case 80:case 81:case 82:case 68:case 67:case 164:case 11:case 29:case 47:case 48:case 95:case 52:case 51:case 46:case 79:return 200809;case 27:case 246:case 127:case 128:case 23:case 24:case 160:case 161:case 181:case 182:case 242:case 183:case 184:case 243:case 244:case 245:case 165:case 178:case 179:case 49:case 50:case 168:case 169:case 175:case 170:case 171:case 172:case 97:case 76:case 32:case 173:case 35:return-1;
case 176:case 177:case 7:case 155:case 8:case 157:case 125:case 126:case 92:case 93:case 129:case 130:case 131:case 94:case 91:return 1;case 74:case 60:case 69:case 70:case 4:return 1024;case 31:case 42:case 72:return 32;case 87:case 26:case 33:return 2147483647;case 34:case 1:return 47839;case 38:case 36:return 99;case 43:case 37:return 2048;case 0:return 2097152;case 3:return 65536;case 28:return 32768;case 44:return 32767;case 75:return 16384;case 39:return 1E3;case 89:return 700;case 71:return 256;
case 40:return 255;case 2:return 100;case 180:return 64;case 25:return 20;case 5:return 16;case 6:return 6;case 73:return 4;case 84:return"object"===typeof navigator?navigator.hardwareConcurrency||1:1}G[Vd()>>2]=28;return-1}};
(function(){function a(h){b.asm=h.exports;ra=b.asm.memory;A(ra,"memory not found in wasm exports");Ra();Ta=b.asm.__indirect_function_table;A(Ta,"table not found in wasm exports");mb("wasm-instantiate")}function c(h){A(b===g,"the Module object should not be replaced during async compilation - perhaps the order of HTML elements is wrong?");g=null;a(h.instance)}function d(h){return sb().then(function(k){return WebAssembly.instantiate(k,e)}).then(h,function(k){r("failed to asynchronously prepare wasm: "+
k);f(k)})}var e={env:Wd,wasi_snapshot_preview1:Wd};lb("wasm-instantiate");var g=b;if(b.instantiateWasm)try{return b.instantiateWasm(e,a)}catch(h){return r("Module.instantiateWasm callback failed with error: "+h),!1}(function(){return na||"function"!==typeof WebAssembly.instantiateStreaming||ob()||"function"!==typeof fetch?d(c):fetch(pb,{credentials:"same-origin"}).then(function(h){return WebAssembly.instantiateStreaming(h,e).then(c,function(k){r("wasm streaming compile failed: "+k);r("falling back to ArrayBuffer instantiation");
return d(c)})})})().catch(ba);return{}})();var Ud=b.___wasm_call_ctors=K("__wasm_call_ctors"),Pb=b._malloc=K("malloc"),zd=b._free=K("free");b._fflush=K("fflush");b._initTracker=K("initTracker");b._destroyTracker=K("destroyTracker");b._process_landmarks=K("process_landmarks");b._track=K("track");b.___original_main=K("__original_main");b._main=K("main");var Vd=b.___errno_location=K("__errno_location"),yd=b.___getTypeName=K("__getTypeName");b.___embind_register_native_and_builtin_types=K("__embind_register_native_and_builtin_types");
b._emscripten_main_thread_process_queued_calls=K("emscripten_main_thread_process_queued_calls");var Wa=b._emscripten_stack_get_end=function(){return(Wa=b._emscripten_stack_get_end=b.asm.emscripten_stack_get_end).apply(null,arguments)},ya=b.stackSave=K("stackSave"),za=b.stackRestore=K("stackRestore"),va=b.stackAlloc=K("stackAlloc"),Xd=b._emscripten_stack_init=function(){return(Xd=b._emscripten_stack_init=b.asm.emscripten_stack_init).apply(null,arguments)};
b._emscripten_stack_get_free=function(){return(b._emscripten_stack_get_free=b.asm.emscripten_stack_get_free).apply(null,arguments)};b._setThrew=K("setThrew");b.dynCall_ji=K("dynCall_ji");b.dynCall_viijii=K("dynCall_viijii");b.dynCall_jiji=K("dynCall_jiji");b.dynCall_iiiiij=K("dynCall_iiiiij");b.dynCall_iiiiijj=K("dynCall_iiiiijj");b.dynCall_iiiiiijj=K("dynCall_iiiiiijj");Object.getOwnPropertyDescriptor(b,"intArrayFromString")||(b.intArrayFromString=function(){f("'intArrayFromString' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"intArrayToString")||(b.intArrayToString=function(){f("'intArrayToString' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"ccall")||(b.ccall=function(){f("'ccall' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});b.cwrap=function(a,c,d){return function(){return ua(a,c,d,arguments)}};Object.getOwnPropertyDescriptor(b,"setValue")||(b.setValue=function(){f("'setValue' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"getValue")||(b.getValue=function(){f("'getValue' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"allocate")||(b.allocate=function(){f("'allocate' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"UTF8ArrayToString")||(b.UTF8ArrayToString=function(){f("'UTF8ArrayToString' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"UTF8ToString")||(b.UTF8ToString=function(){f("'UTF8ToString' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"stringToUTF8Array")||(b.stringToUTF8Array=function(){f("'stringToUTF8Array' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"stringToUTF8")||(b.stringToUTF8=function(){f("'stringToUTF8' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"lengthBytesUTF8")||(b.lengthBytesUTF8=function(){f("'lengthBytesUTF8' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"stackTrace")||(b.stackTrace=function(){f("'stackTrace' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"addOnPreRun")||(b.addOnPreRun=function(){f("'addOnPreRun' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"addOnInit")||(b.addOnInit=function(){f("'addOnInit' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"addOnPreMain")||(b.addOnPreMain=function(){f("'addOnPreMain' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"addOnExit")||(b.addOnExit=function(){f("'addOnExit' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"addOnPostRun")||(b.addOnPostRun=function(){f("'addOnPostRun' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"writeStringToMemory")||(b.writeStringToMemory=function(){f("'writeStringToMemory' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"writeArrayToMemory")||(b.writeArrayToMemory=function(){f("'writeArrayToMemory' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"writeAsciiToMemory")||(b.writeAsciiToMemory=function(){f("'writeAsciiToMemory' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});b.addRunDependency=lb;b.removeRunDependency=mb;Object.getOwnPropertyDescriptor(b,"FS_createFolder")||(b.FS_createFolder=function(){f("'FS_createFolder' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});b.FS_createPath=xc;b.FS_createDataFile=zc;b.FS_createPreloadedFile=Cc;
b.FS_createLazyFile=Bc;Object.getOwnPropertyDescriptor(b,"FS_createLink")||(b.FS_createLink=function(){f("'FS_createLink' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});b.FS_createDevice=R;b.FS_unlink=nc;Object.getOwnPropertyDescriptor(b,"getLEB")||(b.getLEB=function(){f("'getLEB' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"getFunctionTables")||(b.getFunctionTables=function(){f("'getFunctionTables' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"alignFunctionTables")||(b.alignFunctionTables=function(){f("'alignFunctionTables' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"registerFunctions")||(b.registerFunctions=function(){f("'registerFunctions' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"addFunction")||(b.addFunction=function(){f("'addFunction' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"removeFunction")||(b.removeFunction=function(){f("'removeFunction' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"getFuncWrapper")||(b.getFuncWrapper=function(){f("'getFuncWrapper' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"prettyPrint")||(b.prettyPrint=function(){f("'prettyPrint' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"makeBigInt")||(b.makeBigInt=function(){f("'makeBigInt' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"dynCall")||(b.dynCall=function(){f("'dynCall' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"getCompilerSetting")||(b.getCompilerSetting=function(){f("'getCompilerSetting' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"print")||(b.print=function(){f("'print' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"printErr")||(b.printErr=function(){f("'printErr' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"getTempRet0")||(b.getTempRet0=function(){f("'getTempRet0' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"setTempRet0")||(b.setTempRet0=function(){f("'setTempRet0' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"callMain")||(b.callMain=function(){f("'callMain' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"abort")||(b.abort=function(){f("'abort' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"stringToNewUTF8")||(b.stringToNewUTF8=function(){f("'stringToNewUTF8' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"setFileTime")||(b.setFileTime=function(){f("'setFileTime' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"emscripten_realloc_buffer")||(b.emscripten_realloc_buffer=function(){f("'emscripten_realloc_buffer' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"ENV")||(b.ENV=function(){f("'ENV' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"ERRNO_CODES")||(b.ERRNO_CODES=function(){f("'ERRNO_CODES' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"ERRNO_MESSAGES")||(b.ERRNO_MESSAGES=function(){f("'ERRNO_MESSAGES' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"setErrNo")||(b.setErrNo=function(){f("'setErrNo' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"DNS")||(b.DNS=function(){f("'DNS' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"getHostByName")||(b.getHostByName=function(){f("'getHostByName' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"GAI_ERRNO_MESSAGES")||(b.GAI_ERRNO_MESSAGES=function(){f("'GAI_ERRNO_MESSAGES' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"Protocols")||(b.Protocols=function(){f("'Protocols' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"Sockets")||(b.Sockets=function(){f("'Sockets' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"getRandomDevice")||(b.getRandomDevice=function(){f("'getRandomDevice' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"traverseStack")||(b.traverseStack=function(){f("'traverseStack' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"UNWIND_CACHE")||(b.UNWIND_CACHE=function(){f("'UNWIND_CACHE' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"withBuiltinMalloc")||(b.withBuiltinMalloc=function(){f("'withBuiltinMalloc' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"readAsmConstArgsArray")||(b.readAsmConstArgsArray=function(){f("'readAsmConstArgsArray' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"readAsmConstArgs")||(b.readAsmConstArgs=function(){f("'readAsmConstArgs' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"mainThreadEM_ASM")||(b.mainThreadEM_ASM=function(){f("'mainThreadEM_ASM' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"jstoi_q")||(b.jstoi_q=function(){f("'jstoi_q' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"jstoi_s")||(b.jstoi_s=function(){f("'jstoi_s' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"getExecutableName")||(b.getExecutableName=function(){f("'getExecutableName' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"listenOnce")||(b.listenOnce=function(){f("'listenOnce' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"autoResumeAudioContext")||(b.autoResumeAudioContext=function(){f("'autoResumeAudioContext' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"dynCallLegacy")||(b.dynCallLegacy=function(){f("'dynCallLegacy' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"getDynCaller")||(b.getDynCaller=function(){f("'getDynCaller' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"dynCall")||(b.dynCall=function(){f("'dynCall' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"callRuntimeCallbacks")||(b.callRuntimeCallbacks=function(){f("'callRuntimeCallbacks' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"abortStackOverflow")||(b.abortStackOverflow=function(){f("'abortStackOverflow' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"reallyNegative")||(b.reallyNegative=function(){f("'reallyNegative' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"unSign")||(b.unSign=function(){f("'unSign' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"reSign")||(b.reSign=function(){f("'reSign' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"formatString")||(b.formatString=function(){f("'formatString' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"PATH")||(b.PATH=function(){f("'PATH' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"PATH_FS")||(b.PATH_FS=function(){f("'PATH_FS' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"SYSCALLS")||(b.SYSCALLS=function(){f("'SYSCALLS' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"syscallMmap2")||(b.syscallMmap2=function(){f("'syscallMmap2' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"syscallMunmap")||(b.syscallMunmap=function(){f("'syscallMunmap' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"JSEvents")||(b.JSEvents=function(){f("'JSEvents' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"specialHTMLTargets")||(b.specialHTMLTargets=function(){f("'specialHTMLTargets' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"maybeCStringToJsString")||(b.maybeCStringToJsString=function(){f("'maybeCStringToJsString' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"findEventTarget")||(b.findEventTarget=function(){f("'findEventTarget' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"findCanvasEventTarget")||(b.findCanvasEventTarget=function(){f("'findCanvasEventTarget' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"polyfillSetImmediate")||(b.polyfillSetImmediate=function(){f("'polyfillSetImmediate' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"demangle")||(b.demangle=function(){f("'demangle' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"demangleAll")||(b.demangleAll=function(){f("'demangleAll' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"jsStackTrace")||(b.jsStackTrace=function(){f("'jsStackTrace' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"stackTrace")||(b.stackTrace=function(){f("'stackTrace' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"getEnvStrings")||(b.getEnvStrings=function(){f("'getEnvStrings' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"checkWasiClock")||(b.checkWasiClock=function(){f("'checkWasiClock' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"writeI53ToI64")||(b.writeI53ToI64=function(){f("'writeI53ToI64' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"writeI53ToI64Clamped")||(b.writeI53ToI64Clamped=function(){f("'writeI53ToI64Clamped' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"writeI53ToI64Signaling")||(b.writeI53ToI64Signaling=function(){f("'writeI53ToI64Signaling' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"writeI53ToU64Clamped")||(b.writeI53ToU64Clamped=function(){f("'writeI53ToU64Clamped' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"writeI53ToU64Signaling")||(b.writeI53ToU64Signaling=function(){f("'writeI53ToU64Signaling' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"readI53FromI64")||(b.readI53FromI64=function(){f("'readI53FromI64' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"readI53FromU64")||(b.readI53FromU64=function(){f("'readI53FromU64' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"convertI32PairToI53")||(b.convertI32PairToI53=function(){f("'convertI32PairToI53' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"convertU32PairToI53")||(b.convertU32PairToI53=function(){f("'convertU32PairToI53' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"uncaughtExceptionCount")||(b.uncaughtExceptionCount=function(){f("'uncaughtExceptionCount' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"exceptionLast")||(b.exceptionLast=function(){f("'exceptionLast' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"exceptionCaught")||(b.exceptionCaught=function(){f("'exceptionCaught' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"ExceptionInfoAttrs")||(b.ExceptionInfoAttrs=function(){f("'ExceptionInfoAttrs' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"ExceptionInfo")||(b.ExceptionInfo=function(){f("'ExceptionInfo' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"CatchInfo")||(b.CatchInfo=function(){f("'CatchInfo' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"exception_addRef")||(b.exception_addRef=function(){f("'exception_addRef' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"exception_decRef")||(b.exception_decRef=function(){f("'exception_decRef' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"Browser")||(b.Browser=function(){f("'Browser' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"funcWrappers")||(b.funcWrappers=function(){f("'funcWrappers' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"getFuncWrapper")||(b.getFuncWrapper=function(){f("'getFuncWrapper' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"setMainLoop")||(b.setMainLoop=function(){f("'setMainLoop' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"FS")||(b.FS=function(){f("'FS' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"mmapAlloc")||(b.mmapAlloc=function(){f("'mmapAlloc' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"MEMFS")||(b.MEMFS=function(){f("'MEMFS' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"TTY")||(b.TTY=function(){f("'TTY' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"PIPEFS")||(b.PIPEFS=function(){f("'PIPEFS' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"SOCKFS")||(b.SOCKFS=function(){f("'SOCKFS' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"tempFixedLengthArray")||(b.tempFixedLengthArray=function(){f("'tempFixedLengthArray' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"miniTempWebGLFloatBuffers")||(b.miniTempWebGLFloatBuffers=function(){f("'miniTempWebGLFloatBuffers' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"heapObjectForWebGLType")||(b.heapObjectForWebGLType=function(){f("'heapObjectForWebGLType' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"heapAccessShiftForWebGLHeap")||(b.heapAccessShiftForWebGLHeap=function(){f("'heapAccessShiftForWebGLHeap' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"GL")||(b.GL=function(){f("'GL' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"emscriptenWebGLGet")||(b.emscriptenWebGLGet=function(){f("'emscriptenWebGLGet' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"computeUnpackAlignedImageSize")||(b.computeUnpackAlignedImageSize=function(){f("'computeUnpackAlignedImageSize' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"emscriptenWebGLGetTexPixelData")||(b.emscriptenWebGLGetTexPixelData=function(){f("'emscriptenWebGLGetTexPixelData' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"emscriptenWebGLGetUniform")||(b.emscriptenWebGLGetUniform=function(){f("'emscriptenWebGLGetUniform' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"emscriptenWebGLGetVertexAttrib")||(b.emscriptenWebGLGetVertexAttrib=function(){f("'emscriptenWebGLGetVertexAttrib' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"writeGLArray")||(b.writeGLArray=function(){f("'writeGLArray' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"AL")||(b.AL=function(){f("'AL' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"SDL_unicode")||(b.SDL_unicode=function(){f("'SDL_unicode' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"SDL_ttfContext")||(b.SDL_ttfContext=function(){f("'SDL_ttfContext' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"SDL_audio")||(b.SDL_audio=function(){f("'SDL_audio' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"SDL")||(b.SDL=function(){f("'SDL' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"SDL_gfx")||(b.SDL_gfx=function(){f("'SDL_gfx' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"GLUT")||(b.GLUT=function(){f("'GLUT' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"EGL")||(b.EGL=function(){f("'EGL' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"GLFW_Window")||(b.GLFW_Window=function(){f("'GLFW_Window' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"GLFW")||(b.GLFW=function(){f("'GLFW' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"GLEW")||(b.GLEW=function(){f("'GLEW' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"IDBStore")||(b.IDBStore=function(){f("'IDBStore' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"runAndAbortIfError")||(b.runAndAbortIfError=function(){f("'runAndAbortIfError' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"emval_handle_array")||(b.emval_handle_array=function(){f("'emval_handle_array' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"emval_free_list")||(b.emval_free_list=function(){f("'emval_free_list' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"emval_symbols")||(b.emval_symbols=function(){f("'emval_symbols' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"init_emval")||(b.init_emval=function(){f("'init_emval' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"count_emval_handles")||(b.count_emval_handles=function(){f("'count_emval_handles' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"get_first_emval")||(b.get_first_emval=function(){f("'get_first_emval' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"getStringOrSymbol")||(b.getStringOrSymbol=function(){f("'getStringOrSymbol' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"requireHandle")||(b.requireHandle=function(){f("'requireHandle' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"emval_newers")||(b.emval_newers=function(){f("'emval_newers' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"craftEmvalAllocator")||(b.craftEmvalAllocator=function(){f("'craftEmvalAllocator' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"emval_get_global")||(b.emval_get_global=function(){f("'emval_get_global' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"emval_methodCallers")||(b.emval_methodCallers=function(){f("'emval_methodCallers' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"InternalError")||(b.InternalError=function(){f("'InternalError' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"BindingError")||(b.BindingError=function(){f("'BindingError' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"UnboundTypeError")||(b.UnboundTypeError=function(){f("'UnboundTypeError' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"PureVirtualError")||(b.PureVirtualError=function(){f("'PureVirtualError' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"init_embind")||(b.init_embind=function(){f("'init_embind' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"throwInternalError")||(b.throwInternalError=function(){f("'throwInternalError' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"throwBindingError")||(b.throwBindingError=function(){f("'throwBindingError' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"throwUnboundTypeError")||(b.throwUnboundTypeError=function(){f("'throwUnboundTypeError' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"ensureOverloadTable")||(b.ensureOverloadTable=function(){f("'ensureOverloadTable' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"exposePublicSymbol")||(b.exposePublicSymbol=function(){f("'exposePublicSymbol' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"replacePublicSymbol")||(b.replacePublicSymbol=function(){f("'replacePublicSymbol' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"extendError")||(b.extendError=function(){f("'extendError' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"createNamedFunction")||(b.createNamedFunction=function(){f("'createNamedFunction' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"registeredInstances")||(b.registeredInstances=function(){f("'registeredInstances' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"getBasestPointer")||(b.getBasestPointer=function(){f("'getBasestPointer' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"registerInheritedInstance")||(b.registerInheritedInstance=function(){f("'registerInheritedInstance' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"unregisterInheritedInstance")||(b.unregisterInheritedInstance=function(){f("'unregisterInheritedInstance' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"getInheritedInstance")||(b.getInheritedInstance=function(){f("'getInheritedInstance' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"getInheritedInstanceCount")||(b.getInheritedInstanceCount=function(){f("'getInheritedInstanceCount' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"getLiveInheritedInstances")||(b.getLiveInheritedInstances=function(){f("'getLiveInheritedInstances' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"registeredTypes")||(b.registeredTypes=function(){f("'registeredTypes' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"awaitingDependencies")||(b.awaitingDependencies=function(){f("'awaitingDependencies' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"typeDependencies")||(b.typeDependencies=function(){f("'typeDependencies' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"registeredPointers")||(b.registeredPointers=function(){f("'registeredPointers' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"registerType")||(b.registerType=function(){f("'registerType' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"whenDependentTypesAreResolved")||(b.whenDependentTypesAreResolved=function(){f("'whenDependentTypesAreResolved' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"embind_charCodes")||(b.embind_charCodes=function(){f("'embind_charCodes' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"embind_init_charCodes")||(b.embind_init_charCodes=function(){f("'embind_init_charCodes' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"readLatin1String")||(b.readLatin1String=function(){f("'readLatin1String' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"getTypeName")||(b.getTypeName=function(){f("'getTypeName' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"heap32VectorToArray")||(b.heap32VectorToArray=function(){f("'heap32VectorToArray' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"requireRegisteredType")||(b.requireRegisteredType=function(){f("'requireRegisteredType' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"getShiftFromSize")||(b.getShiftFromSize=function(){f("'getShiftFromSize' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"integerReadValueFromPointer")||(b.integerReadValueFromPointer=function(){f("'integerReadValueFromPointer' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"enumReadValueFromPointer")||(b.enumReadValueFromPointer=function(){f("'enumReadValueFromPointer' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"floatReadValueFromPointer")||(b.floatReadValueFromPointer=function(){f("'floatReadValueFromPointer' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"simpleReadValueFromPointer")||(b.simpleReadValueFromPointer=function(){f("'simpleReadValueFromPointer' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"runDestructors")||(b.runDestructors=function(){f("'runDestructors' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"new_")||(b.new_=function(){f("'new_' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"craftInvokerFunction")||(b.craftInvokerFunction=function(){f("'craftInvokerFunction' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"embind__requireFunction")||(b.embind__requireFunction=function(){f("'embind__requireFunction' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"tupleRegistrations")||(b.tupleRegistrations=function(){f("'tupleRegistrations' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"structRegistrations")||(b.structRegistrations=function(){f("'structRegistrations' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"genericPointerToWireType")||(b.genericPointerToWireType=function(){f("'genericPointerToWireType' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"constNoSmartPtrRawPointerToWireType")||(b.constNoSmartPtrRawPointerToWireType=function(){f("'constNoSmartPtrRawPointerToWireType' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"nonConstNoSmartPtrRawPointerToWireType")||(b.nonConstNoSmartPtrRawPointerToWireType=function(){f("'nonConstNoSmartPtrRawPointerToWireType' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"init_RegisteredPointer")||(b.init_RegisteredPointer=function(){f("'init_RegisteredPointer' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"RegisteredPointer")||(b.RegisteredPointer=function(){f("'RegisteredPointer' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"RegisteredPointer_getPointee")||(b.RegisteredPointer_getPointee=function(){f("'RegisteredPointer_getPointee' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"RegisteredPointer_destructor")||(b.RegisteredPointer_destructor=function(){f("'RegisteredPointer_destructor' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"RegisteredPointer_deleteObject")||(b.RegisteredPointer_deleteObject=function(){f("'RegisteredPointer_deleteObject' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"RegisteredPointer_fromWireType")||(b.RegisteredPointer_fromWireType=function(){f("'RegisteredPointer_fromWireType' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"runDestructor")||(b.runDestructor=function(){f("'runDestructor' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"releaseClassHandle")||(b.releaseClassHandle=function(){f("'releaseClassHandle' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"finalizationGroup")||(b.finalizationGroup=function(){f("'finalizationGroup' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"detachFinalizer_deps")||(b.detachFinalizer_deps=function(){f("'detachFinalizer_deps' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"detachFinalizer")||(b.detachFinalizer=function(){f("'detachFinalizer' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"attachFinalizer")||(b.attachFinalizer=function(){f("'attachFinalizer' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"makeClassHandle")||(b.makeClassHandle=function(){f("'makeClassHandle' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"init_ClassHandle")||(b.init_ClassHandle=function(){f("'init_ClassHandle' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"ClassHandle")||(b.ClassHandle=function(){f("'ClassHandle' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"ClassHandle_isAliasOf")||(b.ClassHandle_isAliasOf=function(){f("'ClassHandle_isAliasOf' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"throwInstanceAlreadyDeleted")||(b.throwInstanceAlreadyDeleted=function(){f("'throwInstanceAlreadyDeleted' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"ClassHandle_clone")||(b.ClassHandle_clone=function(){f("'ClassHandle_clone' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"ClassHandle_delete")||(b.ClassHandle_delete=function(){f("'ClassHandle_delete' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"deletionQueue")||(b.deletionQueue=function(){f("'deletionQueue' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"ClassHandle_isDeleted")||(b.ClassHandle_isDeleted=function(){f("'ClassHandle_isDeleted' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"ClassHandle_deleteLater")||(b.ClassHandle_deleteLater=function(){f("'ClassHandle_deleteLater' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"flushPendingDeletes")||(b.flushPendingDeletes=function(){f("'flushPendingDeletes' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"delayFunction")||(b.delayFunction=function(){f("'delayFunction' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"setDelayFunction")||(b.setDelayFunction=function(){f("'setDelayFunction' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"RegisteredClass")||(b.RegisteredClass=function(){f("'RegisteredClass' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"shallowCopyInternalPointer")||(b.shallowCopyInternalPointer=function(){f("'shallowCopyInternalPointer' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"downcastPointer")||(b.downcastPointer=function(){f("'downcastPointer' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"upcastPointer")||(b.upcastPointer=function(){f("'upcastPointer' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"validateThis")||(b.validateThis=function(){f("'validateThis' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"char_0")||(b.char_0=function(){f("'char_0' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"char_9")||(b.char_9=function(){f("'char_9' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"makeLegalFunctionName")||(b.makeLegalFunctionName=function(){f("'makeLegalFunctionName' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"warnOnce")||(b.warnOnce=function(){f("'warnOnce' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"stackSave")||(b.stackSave=function(){f("'stackSave' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"stackRestore")||(b.stackRestore=function(){f("'stackRestore' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"stackAlloc")||(b.stackAlloc=function(){f("'stackAlloc' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"AsciiToString")||(b.AsciiToString=function(){f("'AsciiToString' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"stringToAscii")||(b.stringToAscii=function(){f("'stringToAscii' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"UTF16ToString")||(b.UTF16ToString=function(){f("'UTF16ToString' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"stringToUTF16")||(b.stringToUTF16=function(){f("'stringToUTF16' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"lengthBytesUTF16")||(b.lengthBytesUTF16=function(){f("'lengthBytesUTF16' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"UTF32ToString")||(b.UTF32ToString=function(){f("'UTF32ToString' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"stringToUTF32")||(b.stringToUTF32=function(){f("'stringToUTF32' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
Object.getOwnPropertyDescriptor(b,"lengthBytesUTF32")||(b.lengthBytesUTF32=function(){f("'lengthBytesUTF32' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"allocateUTF8")||(b.allocateUTF8=function(){f("'allocateUTF8' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});Object.getOwnPropertyDescriptor(b,"allocateUTF8OnStack")||(b.allocateUTF8OnStack=function(){f("'allocateUTF8OnStack' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")});
b.writeStackCookie=Ua;b.checkStackCookie=Xa;Object.getOwnPropertyDescriptor(b,"ALLOC_NORMAL")||Object.defineProperty(b,"ALLOC_NORMAL",{configurable:!0,get:function(){f("'ALLOC_NORMAL' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")}});Object.getOwnPropertyDescriptor(b,"ALLOC_STACK")||Object.defineProperty(b,"ALLOC_STACK",{configurable:!0,get:function(){f("'ALLOC_STACK' was not exported. add it to EXTRA_EXPORTED_RUNTIME_METHODS (see the FAQ)")}});var Yd;
function Zd(a){this.name="ExitStatus";this.message="Program terminated with exit("+a+")";this.status=a}ib=function $d(){Yd||ae();Yd||(ib=$d)};
function ae(){function a(){if(!Yd&&(Yd=!0,b.calledRun=!0,!sa)){Xa();A(!db);db=!0;if(!b.noFSInit&&!vc){A(!vc,"FS.init was previously called. If you want to initialize later with custom parameters, remove any earlier calls (note that one is automatically added to the generated code)");vc=!0;uc();b.stdin=b.stdin;b.stdout=b.stdout;b.stderr=b.stderr;b.stdin?R("/dev","stdin",b.stdin):mc("/dev/tty","/dev/stdin");b.stdout?R("/dev","stdout",null,b.stdout):mc("/dev/tty","/dev/stdout");b.stderr?R("/dev","stderr",
null,b.stderr):mc("/dev/tty1","/dev/stderr");var c=pc("/dev/stdin",0),d=pc("/dev/stdout",1),e=pc("/dev/stderr",1);A(0===c.s,"invalid handle for stdin ("+c.s+")");A(1===d.s,"invalid handle for stdout ("+d.s+")");A(2===e.s,"invalid handle for stderr ("+e.s+")")}vb(ab);Xa();Xb=!1;vb(bb);aa(b);if(b.onRuntimeInitialized)b.onRuntimeInitialized();if(be){A(0==gb,'cannot call main when async dependencies remain! (listen on Module["onRuntimeInitialized"])');A(0==$a.length,"cannot call main when preRun functions remain to be called");
c=b._main;try{var g=c(0,0);ce();if(!noExitRuntime||0!==g){if(!noExitRuntime){Xa();eb=!0;if(b.onExit)b.onExit(g);sa=!0}fa(g,new Zd(g))}}catch(h){h instanceof Zd||("unwind"==h?noExitRuntime=!0:((g=h)&&"object"===typeof h&&h.stack&&(g=[h,h.stack]),r("exception thrown: "+g),fa(1,h)))}finally{}}Xa();if(b.postRun)for("function"==typeof b.postRun&&(b.postRun=[b.postRun]);b.postRun.length;)g=b.postRun.shift(),cb.unshift(g);vb(cb)}}if(!(0<gb)){Xd();Ua();if(b.preRun)for("function"==typeof b.preRun&&(b.preRun=
[b.preRun]);b.preRun.length;)fb();vb($a);0<gb||(b.setStatus?(b.setStatus("Running..."),setTimeout(function(){setTimeout(function(){b.setStatus("")},1);a()},1)):a(),Xa())}}b.run=ae;
function ce(){var a=ka,c=r,d=!1;ka=r=function(){d=!0};try{var e=b._fflush;e&&e(0);["stdout","stderr"].forEach(function(g){g="/dev/"+g;try{var h=P(g,{V:!0});g=h.path}catch(m){}var k={$a:!1,Oa:!1,error:0,name:null,path:null,object:null,bb:!1,eb:null,cb:null};try{h=P(g,{parent:!0}),k.bb=!0,k.eb=h.path,k.cb=h.node,k.name=Bb(g),h=P(g,{V:!0}),k.Oa=!0,k.path=h.path,k.object=h.node,k.name=h.node.name,k.$a="/"===h.path}catch(m){k.error=m.C}k&&(h=Eb[k.object.Z])&&h.o&&h.o.length&&(d=!0)})}catch(g){}ka=a;r=
c;d&&la("stdio streams had content in them that was not flushed. you should set EXIT_RUNTIME to 1 (see the FAQ), or make sure to emit a newline when you printf etc.")}if(b.preInit)for("function"==typeof b.preInit&&(b.preInit=[b.preInit]);0<b.preInit.length;)b.preInit.pop()();var be=!0;b.noInitialRun&&(be=!1);noExitRuntime=!0;ae();


  return Module.ready
}
);
})();
export default Module;